@import "~styles/colors";
@import "~styles/typography";

.defaultline {
  color: #001eba;
  cursor: pointer;
  text-decoration: underline;
}
.button_link {
  color: #001eba;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
