@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px 120px 38px 90px;
  margin: 50px 0px;
  .label {
    position: initial;
    @include font($mainFont, 14px, 16px);
    color: $mainText;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 16px;
    color: #2b3858;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}