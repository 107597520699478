@import "~styles/colors";
@import "~styles/typography";

.header {
  padding: 25px 25px 25px 30px;
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
}

.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 0;

  & > button {
    align-self: flex-start;
  }
}

.scrollableList {
  height: 205px;
  padding: 13px 20px 30px 30px;
  @include font($mainFont, 16px, 16.25px);
  color: $mainText;

  & ul {
    height: 165px;
    overflow-y: auto;
    padding: 0;
    padding-right: 6px;
    margin: 0;

    & li {
      text-align: start;
      height: 55px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $dividerColor;
      cursor: pointer;
    }
  }

  & a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: #83b0fd;
    }
  }

  & .assetHead {
    width: calc(100% - 12px); // padding + scrollbar from list
    padding-bottom: 10px;

    & span {
      @include font($mediumFont, 16px, 19px);
    }
  }

  & .assetRow {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    cursor: initial;

    & .select {
      width: 90%;
    }
  }

  & .noData {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.scrollableWithHeader {
  height: 150px !important;
}

.loader {
  padding: 15px;
}

.text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
