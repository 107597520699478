@import "~styles/colors";
@import "~styles/typography";

.container {
  margin-bottom: 30px;
  box-shadow: 0px 0px 26px rgba(161, 165, 180, 0.08);
  border-radius: 15px;
  padding: 30px;
}

.inputsCont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 30px;
}

.buttonFooter {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.bottom {
  margin-top: 30px;
}
.sub_title {
  @include font($boldFont, 16px, 13px);
  color: #8490a5;
}

.btnRemove {
  display: flex;
  align-items: flex-end;
}
