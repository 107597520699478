@import "~styles/colors";
@import "~styles/typography";

.container {
  position: relative;

  & .label {
    margin-bottom: 10px !important;
    @include font($mainFont, 14px, 16px);
    color: $mainText;
  }

  .block {
    display: flex;
    background: #ffffff;
    border: 1px solid #2b3858;
    box-sizing: border-box;
    border-radius: 10px;
    height: 40px;
    .item {
      display: flex;
      flex: 1;
      margin: 2px 2px 2px 2px;
      @include font($boldFont, 14px, 16px);
      color: #959eb5;
      border-radius: 7px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.active {
        color: #fff;
        background: #2b3858;
      }
    }
  }
}
