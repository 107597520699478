.node_image {
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
}

.node_selected {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.8));
  opacity: 1;
}

.selected-connect {
  /* filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.8)); */
  opacity: 1;
}

.node_image:hover {
  /* width: 105px;
    height: 105px; */
  /* filter: drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.8)); */
  cursor: pointer;
}

.edge {
  opacity: 0.2;
}

.edge:hover {
  /* filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.8)); */
  cursor: pointer;
  opacity: 1;
}

.edge_highlight {
  opacity: 1;
}

.highlight_connection {
  opacity: 1;
}

.glass {
  opacity: 0.05;
}

.btn {
  background: #ccc;
  color: #111;
  padding: 10px;
  margin: 4px;
  border-radius: 4px;
}
.tooltip {
  width: max-content;
  max-width: 250px;
  background-color: #282828;
  color: #fff;
  border-radius: 4px;
  padding: 2px 4px;
  position: absolute;
  z-index: 1;
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
  justify-content: center;
  word-break: break-all;
}
.graph-action-btns {
  background: white;
  font-size: 22px;
  font-weight: 400;
  box-shadow: 0 6px 6px rgb(182 182 182 / 25%);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 4;
  cursor: pointer;
}

.graph-action-btns:hover {
  background: #f4f7ff;
}

.zoom-in-btn {
  background: white;
  font-size: 22px;
  font-weight: 400;
  box-shadow: 0 6px 6px rgb(182 182 182 / 25%);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  cursor: pointer;
}

.zoom-out-btn {
  background: white;
  font-size: 22px;
  font-weight: 400;
  box-shadow: 0 6px 6px rgb(182 182 182 / 25%);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  cursor: pointer;
}

.node_text {
  font-size: 12px;
  text-anchor: middle;
  font-weight: 700;
}

.node_text2 {
  font-size: 14px;
  /* text-anchor: middle; */
}

.connect_text {
  font-size: 10px;
  text-anchor: middle;
}

.graph-action-btns.reset-btn {
  bottom: 160px;
  /* left: 10px; */
}

.entity-group {
  stroke: #326ce5;
  stroke-dasharray: 5, 5;
  /* fill: #f0f1f1; */
  fill: #f0f1f185;
}

.entity {
  display: inline-block;
  margin: 8px;
  padding: 4px 8px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.rotate_text {
  transform-box: fill-box;
  transform-origin: center;
  transform: rotate(180deg);
}
.selected_path {
  cursor: pointer;
  opacity: 1;
}
.infolink {
  fill: #001eba;
  cursor: pointer;
  font-size: 14px;
}
.infolink:hover {
  text-decoration: underline;
}
