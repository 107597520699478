@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;

  & .searchRow {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;

    & .searchContainer {
      width: 342px;
    }

    & .search {
      background: #f3f5f9;
      padding: 9px 12px !important;
      border: none !important;
    }

    & .btnsContainer {
      display: flex;
      gap: 20px;

      & .btn {
        height: 40px;
        padding: 0 35px;
      }
    }
  }

  & .table {
    margin-top: 30px;
  }
}
