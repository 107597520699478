@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;

  & .searchRow {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-top: 20px;

    & .searchContainer {
      width: 342px;
    }

    & .search {
      background: #f3f5f9;
      padding: 9px 12px !important;
      border: none !important;
    }

    & .btn {
      height: 40px;
      padding: 0 25px;
    }
  }

  & .filterRow {
    display: flex;
    gap: 20px;
    margin-top: 20px;

    & .select {
      width: 180px;
      height: 40px;
    }
  }

  & .table {
    margin-top: 30px;
  }
}

.btnGoBack {
  margin-bottom: 20px;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 30px;

  & .searchContainer {
    width: 342px;
    @include font($mainFont, 14px, 16px, 400);
  }

  & .search {
    background: #f3f5f9;
    padding: 9px 12px !important;
    border: none !important;
  }

  & .select {
    max-width: 200px;
  }

  & .btns {
    display: flex;
    gap: 10px;
  }

  & .btn {
    height: 40px;
    border-radius: 10px;
    padding: 0 24px;
    text-transform: none;
    @include font($mainFont, 14px, 16px);
    color: $white;

    & > svg {
      margin-left: 15px;
    }
  }
  & .btn.create {
    background-color: $mainText;

    &:hover {
      background: rgba($color: #18233f, $alpha: 1);
    }
  }
  & .btn.delete {
    background-color: $greyBackground;

    &:hover {
      background: rgba($color: #92969c, $alpha: 1);
    }
  }

  & a {
    text-decoration: none !important;
  }
}
