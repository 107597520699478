.slide {
  margin: 1rem;

  & .heading {
    & .select {
      max-width: 200px;
    }

    & .tab {
      padding: 1rem;
    }
  }
}
.nodata {
   width: auto;
   text-align: center;
   font-size: 20px;
 }
