.box {
  cursor: pointer;
  height: 93px;
  width: 100%;
  border: 1px solid #dadadb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
