@import "~styles/colors";
@import "~styles/typography";

.legend {
  max-height: 150px;
}
.selectedLabel {
  cursor: pointer;
  &:hover {
    color: #001eba;
    text-decoration: none;
    transition-timing-function: ease-in-out;
  }
}
