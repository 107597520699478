@import "~styles/colors";
@import "~styles/typography";

.container {
  // width: 80%;
  padding: 30px;
  margin: 20px 0px;

  & .help_icon {
    margin-left: 4px;
    font-size: 20px;
    margin-top: 2px;
  }

  & .typo {
    font-size: 14px;
    line-height: 16px;
    color: #2b3858;
  }

  & .firstDiv {
    width: 34%;
  }
  & .infoIcon {
    font-size: 17px;
    position: relative;
    top: 4px;
    right: -1px;
    cursor: pointer;
  }
}