@import "~styles/colors";
@import "~styles/typography";

.content {
  padding: 30px;
  background: #ffff;
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  border-radius: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .head {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-column-gap: 30px;
    margin-bottom: 20px;
    padding: 13px 17px;
    padding-bottom: 0;
    .col {
      display: flex;
      .headerTitle {
        @include font($boldFont, 16px, 13px);
        color: $mainText;
      }
      &:last-child {
        .headerTitle {
          position: relative;
          &::after {
            position: absolute;
            content: "";
            left: -10px;
            top: -4px;
            height: 20px;
            border-left: 2px solid #d9dfea;
          }
        }
      }
    }
  }
  .body {
    .row {
      padding: 13px 17px;
      display: grid;
      grid-template-columns: 2fr 2fr 1fr;
      grid-column-gap: 30px;
      background: #ffffff;
      border: 1px solid #d9dfea;
      box-sizing: border-box;
      border-radius: 10px;
      .col {
        display: flex;
        align-items: center;
      }
      .name {
        @include font($mainFont, 14px, 16px);
        color: $mainText;
      }
    }
    .noData {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      height: 15rem;
      margin-top: 12rem;
    }
  }

  .delete {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
  }
}
