@import "~styles/colors";
@import "~styles/typography";

.title {
  @include font($mediumFont, 24px, 28.6px, 600);
  text-transform: capitalize;
  color: $mainText;
  margin: 0 0 30px;
}

.btnsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  & .btn {
    height: 40px;
    padding: 0 35px;
  }
}
