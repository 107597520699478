@import "~styles/colors";
@import "~styles/typography";

.editor {
  padding: 5px 10px !important;
  margin: 5px 0px;
  background: $secondaryBackground;
  color: $mainText;
  border-radius: 10px;
  overflow-y: scroll !important;
  font-size: 14px;
  letter-spacing: 1.2px;
}

.editorContainer {
  max-height: 300px;
  overflow: auto;
}

.noDataContainer {
  padding: 20px;
  background: $secondaryBackground;
  height: 300px;
  overflow-y: scroll;
  border-radius: 10px;
  white-space: pre-line;
}
