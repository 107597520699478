@import "~styles/colors";
@import "~styles/typography";

.row3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  margin-bottom: 20px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 25px 25px 25px 30px;
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  margin-bottom: 0;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      width: 40px;
      height: 40px;
      background: #f2f5fa;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .iconBtn {
        transform: rotate(135deg);
      }
    }
  }

  .tagsList {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    .tag {
      padding: 10px 18px;
      background: $mainText;
      border-radius: 4px;
      @include font($mainFont, 14px, 16px, 500);
      color: #fff;
    }
  }
}

.chart {
  height: 200px;
}

.tableRow {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid $dividerColor;
  padding: 10px 0;

  & .titleCell {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: #83b0fd;
    }
  }

  & .selectCell {
    width: 90%;
  }
}
