::marker {
  content: "";
}

@keyframes sweep {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

$color_1: #fff;
$color_2: #223cc3;
$color_3: rgb(26, 32, 44);
$color_4: inherit;
$background-color_1: #f5f5f5;
$background-color_2: #c1c1c1;
$background-color_3: #f1f1f1;
$background-color_4: #326ce5;
$border-color_1: #223cc3;

.hybrid-sidebar {
  width: 100%;
  height: 90vh;
  overflow-y: auto;
  padding: 20px 10px 40px 10px;
  border: 1px solid #eee;
  background: #fbfcff;
  position: sticky;
  top: 0;
  &::-webkit-scrollbar {
    width: 7px;
    height: 10px;
    background-color: $background-color_1;
    border-radius: 20px;
    box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $background-color_2;
    border-radius: 20px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
  }
  &::-webkit-scrollbar-track {
    background-color: $background-color_3;
    border-radius: 20px;
    box-shadow: inset 0px 4px 4px rgb(0 0 0 / 5%);
  }
  details {
    font-size: 14px;
  }
  .fz-13 {
    font-size: 13px !important;
    font-weight: 500;
  }
  .dropdown-btn {
    width: 100%;
    height: 38px;
    border: 1px solid #dedede;
    padding: 4px 14px;
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    &:hover {
      background: rgba(249, 250, 251, 1);
    }
    &:focus {
      border-color: $border-color_1;
    }
    &:active {
      border-color: $border-color_1;
    }
    &:focus-within {
      border-color: $border-color_1;
    }
  }
  .dropdown-filter {
    border-radius: 4px;
    font-weight: 500;
    overflow: hidden;
    transition: max-height 0.4s ease;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
    cursor: pointer;
    user-select: none;
    li {
      padding: 8px 14px;
      margin-bottom: 0;
      font-size: 14px;
      &:hover {
        background: rgba(244, 245, 247, 1);
      }
    }
  }
  .arrow-icon {
    transition: transform 0.4s ease;
  }
  .transform-icon {
    transform: rotate(180deg);
    transition: transform 0.4s ease;
  }
  li {
    svg {
      min-width: 16px;
      margin-top: 3px;
    }
    .icon-inactive {
      transition: all 0.1s linear;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  li.active {
    color: $color_2;
    font-weight: 600;
  }
  .search-result-section {
    .active {
      color: $color_2;
      font-weight: 600;
    }
    background: #f4f7ff;
    border-radius: 2px;
    padding: 10px 8px;
    * {
      &::marker {
        content: "";
        display: none;
      }
      &::before {
        content: "";
        display: none;
      }
    }
    details[open] {
      .heading-text {
        color: $color_2;
      }
      .icon-active {
        transform: rotate(90deg);
        transition: all 0.1s linear;
      }
      .entity-icon {
        .fill-color {
          fill: #223cc3;
        }
        .stroke-color {
          stroke: #223cc3;
        }
      }
    }
    details {
      .heading-text {
        color: $color_4;
      }
      .icon-active {
        transform: rotate(0);
        transition: all 0.1s linear;
      }
      .entity-icon {
        .fill-color {
          fill: #a6a8aa;
        }
        .stroke-color {
          stroke: #a6a8aa;
        }
      }
    }
  }
  li.inactive {
    color: $color_3;
  }
  .icon-active {
    transform: rotate(90deg);
    transition: all 0.1s linear;
  }
  .filter-section {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    cursor: pointer;
    div {
      padding: 8px;
      width: 100%;
      text-align: center;
      border: 1px solid #d9d9d9;
      &:nth-child(1) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:nth-child(2) {
        border-left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    .active {
      background: #eaeaea;
      font-weight: 600;
    }
  }
  .active-child {
    color: $color_2;
    font-weight: 600;
  }
  &::placeholder {
    font-size: 14px;
  }
}
details {
  position: relative;
  summary {
    &::before {
      content: "+";
      position: absolute;
      top: 0;
      left: -22px;
      width: 18px;
      height: 18px;
      font-size: 18px;
      background-color: $background-color_4;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color_1;
      padding-bottom: 3px;
      border-radius: 4px;
    }
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  li {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
details[open] {
  .one {
    &::before {
      content: "-";
      position: absolute;
      top: 0;
      left: -22px;
      width: 18px;
      height: 18px;
      background-color: $background-color_4;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color_1;
      padding-bottom: 2px;
    }
  }
  summary {
    ~ {
      * {
        animation: sweep 0.5s ease-in-out;
      }
    }
  }
}
.closeIcon {
  fill: #929597;
  position: absolute;
  right: 6px;
  bottom: 12px;
  &:hover {
    fill: #515558;
  }
}
.subAccordion {
  position: relative;
  padding: 0;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    background: #d9d9d9;
    height: 100%;
    width: 1px;
    display: block;
  }
}
.searchBox {
  position: relative;
  top: 10px;
  display: block;
}
