@import "~styles/colors";
@import "~styles/typography";

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.content {
  margin-top: 38px;
}

.block {
  background: #f9e8e8;
  padding: 17px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  .cont {
    margin-left: 18px;
    .title {
      @include font($mediumFont, 16px, 18px);
      color: $dark;
      margin-bottom: 8px;
    }
    .description {
      @include font($mainFont, 14px, 16px);
      color: $greyText;
    }
  }
}
