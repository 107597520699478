@import "~styles/colors";
@import "~styles/typography";

.wrapper {
  // width: 500px;
  position: relative;

  & .label {
    @include font($mainFont, 14px, 16px);
    color: $mainText;
    margin-bottom: 10px;
  }

  & .noOptions {
    width: 100%;
    text-align: center;
    @include font($mainFont, 14px, 16px);
    color: $mainText;
  }

  & .container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid $dividerColor;
    border-radius: 8px;
    gap: 5px;
    padding: 7px;
    box-sizing: border-box;
  }

  & .tag {
    width: fit-content;
    height: 34px;
    background: $secondaryBackground;
    border-radius: 4px;
    @include font($mainFont, 12px, 14px);
    color: $darkText;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 18px;
    cursor: pointer;
    position: relative;
    align-self: center;

    &:hover {
      background: $mainText;
      color: $white;

      & .removeBtn {
        display: block;
        color: $primaryRed;
      }
    }

    & .removeBtn {
      display: none;
      position: absolute;
      top: -3px;
      right: -3px;
      transform: rotate(90deg);
      line-height: 5px;
    }
  }

  & .tag.selected {
    background: $mainText;
    color: $white;

    &:hover {
      background: $secondaryRed;
      color: $mainText;
    }
  }

  & .addBtn {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-color: $secondaryRed;
    border-radius: 5px;
    margin-left: 5px;
    align-self: center;
  }

  & .modal {
    $height: fit-content;
    width: 100%;
    position: absolute;
    left: 0;
    top: -268px;
    box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
    background-color: $white;
    margin-top: 10px;
    z-index: 2;
  }
}

.searchInput {
  width: 100%;
}

.searchList {
  width: 100%;
  height: 200px;
  overflow-y: auto;

  & :global(.MuiListItem-root) {
    cursor: pointer;
    user-select: none;

    &:hover {
      background: $secondaryBackground;
    }
  }
}
