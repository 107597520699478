@import "~styles/colors";
@import "~styles/typography";

.sidebar-container {
  width: 222px;
  padding-top: 30px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 24px;
  img {
    width: 100%;
  }
}

.sidebarWrapper {
  margin-top: 24px;
  padding: 0 10px;
}

.items-list {
  margin: 0;
  padding: 0;
  text-align: left;
}

.divide {
  border-bottom: 1px solid #fff;
  opacity: 0.3;
  margin: 18px 0;
}

.icon {
  display: flex;
  margin-top: 2px;
}

.title {
  display: flex;
  margin-left: 10px;
}

.list {
  position: relative;
  height: 0;
  opacity: -1;
  overflow: hidden;
  &.open {
    height: auto;
    opacity: 1;
  }
  // &::before {
  //   content: "";
  //   display: block;
  //   width: 10px;
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 25px;
  //   height: 90%;
  //   border-bottom-left-radius: 12%;
  //   border-left: 1px solid rgba(255, 255, 255, 0.3);
  // }
  .itemTree {
    position: relative;
    // &:before {
    //   content: "";
    //   width: 11px;
    //   height: 12px;
    //   border-radius: 0 0 0 100%;
    //   border-bottom: 1px solid rgba(#fff, 0.3);
    //   position: absolute;
    //   top: 13px;
    //   left: -10px;
    // }
  }
}
