@import "~styles/colors";
@import "~styles/typography";

.modalButton {
  margin-top: 20px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
