@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 2%;
}

.action {
  display: flex;
  flex-direction: column;
  background: $white;
  gap: 1rem;
  padding: 15px 0;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    &-singleSelect {
      display: flex;
      gap: 10px;
      &-workload {
        width: 15%;
      }
      &-policyType {
        max-width: 80%;
      }
    }
    &__filter {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 10px;
      &-option {
        min-width: 200px;
      }
    }
  }
}

.actionbtn {
  &-group {
    display: flex;
    // margin-left: auto;
    gap: 10px;
    justify-content: space-betweenI;
  }
}

.action_group {
  display: flex;
  margin-right: 5px;
  width: 20%;
  justify-content: space-between;
  & button {
    font-size: 14px;
    margin-right: 6px;
    & svg {
      margin-right: 4px;
    }
  }
}

.btn_Groups {
  width: 80%;
  @media screen and (max-width: 1500px) {
    width: 67%;
  }
  @media screen and (max-width: 1600px) {
    width: 71%;
  }
  @media screen and (max-width: 1366px) {
    width: 69%;
  }
}

.existingTagList {
  background: #f2f4f7;
  color: #344054;
  height: 22px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
}
