@import "~styles/colors";
@import "~styles/typography";

.hide {
  display: none;
}

.panels {
  position: relative;
}

.loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
}
