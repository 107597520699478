@import "~styles/colors";
@import "~styles/typography";

.inputTokenContainer {
  height: 50px !important;
  z-index: -1;
}

.inputToken {
  border-radius: 10px !important;
  height: 50px !important;
  z-index: -1;
  padding-right: 45px !important;
}

.visibilityIcon {
  position: absolute;
  top: 25%;
  right: 2%;
  cursor: pointer;
}
