.wrapper{
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  font-weight: 500;
  & .removeBtn {
    position: absolute;
    top: 15px;
    right: 15px;
    transform: rotate(90deg);
    line-height: 5px;
    cursor: pointer;
  }
     & .headertext {
       display: inline-block;
       font-size: 12px;
       font-weight: 500;
       text-transform: uppercase;
     }
     .nodeinfovalue {
       float: right;
       word-break: break-all;
     }
}