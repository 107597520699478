* {
  // font-family: "Poppins", "Times New Roman", serif !important;
  font-family: "Inter", "Times New Roman", serif !important;
  box-sizing: border-box !important;
}

.headercontainer {
  background: #eaf3fd;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    color: #001eba;
    text-decoration: underline;
    transition-timing-function: ease-in-out;
  }
}
.lable {
  // overflow: hidden;
  //     white-space: nowrap;
  //     text-overflow: ellipsis;
}
.child {
  max-height: 190px;
  min-height: 150px;
}
.Tooltip {
  width: 180%;
}
.Labelbutton {
  &:hover {
    color: #001eba;
    text-decoration: underline;
    transition-timing-function: ease-in-out;
    cursor: pointer;
  }
}
