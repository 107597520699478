@import "~styles/colors";
@import "~styles/typography";

.content {
  margin-top: 38px;
  height: 300px;
  overflow-y: auto;
  background: #f2f5fa;
  border-radius: 10px;
  .item {
    @include font($mainFont, 14px, 16px);
    color: $dark;
    padding: 20px 20px;
    border-bottom: 1px solid #d9dfea;
    cursor: pointer;
  }
}
