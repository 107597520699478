@import "~styles/colors";
@import "~styles/typography";

.container {
  background: $white;
  padding: 30px;
  box-shadow: 0px 0px 26px rgba(161, 165, 180, 0.08);
  border-radius: 15px;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;
  margin-bottom: 20px;
}

.buttonRow {
  margin-bottom: 30px;
}

.input {
  padding: 8px 12px !important;
  font-size: 14px !important;
}

.list {
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 15px;
    background: #f2f5fa;
    border-radius: 10px;
    margin-bottom: 10px;
    .itemName {
      @include font($mediumFont, 14px, 16px);
      color: #959eb5;
      margin-left: 15px;
    }
    .buttonRemove {
      margin-left: auto;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
}

.backBtnHolder {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  
}
.backLink{
  text-decoration: none !important; 
}