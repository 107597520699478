@import "~styles/colors";
@import "~styles/typography";

.container {
  position: relative;
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0;
  margin: 0;
  &:hover {
    .tooltip {
      display: flex;
    }
  }
  .tooltip {
    white-space: nowrap;
    position: absolute;
    top: -40px;
    // left: -50%;
    // margin-left: -50%;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translateX(-50%);
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 11px;

    background: $secondaryBackground;
    border: 1px solid $dividerColor;
    box-sizing: border-box;
    box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
    border-radius: 8px;

    @include font($lightFont, 14px, 14px);
    color: $mainText;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: $secondaryBackground;
  border-radius: 10px;
  cursor: pointer;
  @include font($mediumFont, 14px, 16px);
  color: $mainText;
  span {
    margin-right: 12px;
  }
  &.widthAuto {
    width: auto;
    padding: 0px 25px;
  }
  &:hover {
    background: #dfebff;
  }
}
