@import "~styles/colors";

.dashboard-container {
  min-width: 1140px;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  overflow-x: auto;
  background: $background;
}

.dashboard-wrapper {
  margin-left: 222px;
  // width: calc(100% - 222px);
  position: relative;
  padding-bottom: 30px;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dashboard-content {
  padding: 0 40px;
  flex: 1;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
  padding-top: 10px;
}
