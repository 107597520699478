@import "~styles/colors";
@import "~styles/typography";

.container {
  margin-bottom: 30px;
  box-shadow: 0px 0px 26px rgba(161, 165, 180, 0.08);
  border-radius: 15px;
  padding: 30px;
}

.head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 30px;

   .noData {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    height: 15rem;
    margin-top: 12rem;
   }
}
