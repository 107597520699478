@import "~styles/colors";
@import "~styles/typography";

.title {
  margin-bottom: 30px;
}

.input {
  margin-bottom: 20px;
}

.savedFilters {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  & .filterBox {
    border: 2px solid gainsboro;
    width: 100%;
    height: 80px;
    border-radius: 16px;
    padding: 10px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  & .btn {
    height: 40px;
    padding: 0 35px;
  }
}
