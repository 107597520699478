@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;
}

.action {
  display: flex;
  flex-direction: column;
  background: $white;
  gap: 1rem;
  padding: 15px 0;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &-group {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &__filter {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      &-option {
        width: 200px;
        min-width: 162px;
      }
    }
  }
}

.actionbtn {
  &-group {
    display: flex;
    // margin-left: auto;
    gap: 10px;
    justify-content: space-between;
  }
}

.saveBtn {
  position: relative;
  top: -3px;
}

.logsFilterContainer {
  display: flex;
}
.logsContainer {
  width: -webkit-fill-available;
}
.sideFilter {
  float: left;
}
.columnLog3 {
  width: 21%;
  padding: 10px 10px 10px 0px;
}
.columnLog9 {
  width: 79%;
  padding: 10px 0px 10px 0px;
}
.columnLog12 {
  width: 100%;
}
[class*="columnLog"] {
  float: left;
}
.border {
  border: 1px solid #dbdff0;
  width: 100%;
}
.critical {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: #f90000;
  position: relative;
  top: 7px;
}
.low {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: #1e8700;
  position: relative;
  top: 7px;
}
.normal {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: #fdd20f;
  position: relative;
  top: 7px;
}
.icon_pencil {
  font-size: 19px;
  position: relative;
  top: 3px;
}
.filterBox {
  border: 2px solid gainsboro;
  width: 100%;
  border-radius: 16px;
  padding: 10px;
}

.button {
  @media screen and (max-width: 1300px) {
    max-width: 100px;
    width: 140px;
    font-size: 12px;
    flex: 1 1;
  }
}

.selectBtn {
  @media screen and (max-width: 1400px) {
    min-width: 100px;
    width: 140px;
  }
}
