@import "~styles/colors";
@import "~styles/typography";

.label {
  @include font($mainFont, 14px, 16px);
  color: $mainText;
  bottom: 7px;
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 7px 10px;
  border: 1px solid $dividerColor;
  border-radius: 8px;
  @include font($mainFont, 13px, 20px);
  caret-color: $primaryRed;
  color: $mainText;
  cursor: pointer;
  z-index: 10000;
}
