@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;

  & .title {
    margin-bottom: 30px;
  }

  & .field {
    max-width: 345px;
    margin-bottom: 18px;
  }

  & .field.withExample {
    margin-bottom: 8px;
  }

  & .textEditor {
    margin: 33px 0 15px;
  }

  & .hint {
    @include font($lightFont, 12px, 14px, 400);
    color: $greyText;
    padding-left: 5px;
  }

  & .btnsCont {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;

    & button {
      width: 130px;
      height: 40px;
      padding: 0 35px;
    }
  }
}
