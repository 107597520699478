@import "~styles/colors";
@import "~styles/typography";

.updateDialogContainer {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & .availableChanges {
    font-size: 8px;
    color: red;
    padding-left: 15px;
    margin-top: auto;
    font-weight: 400;
    // position: relative;
    // left: 140px;
    // top: -28px;
  }

  & .updateYamlBtn {
    background: #3065c6;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    color: white;
    font-size: 12px;
    padding: 6px 1px;
    margin: 8px 0;
  }

  & .updateBtn:hover {
    background: #26437a;
  }
}

.networkName {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  // margin-top: 34px;
  // margin-bottom: 18px;
}

.appliedContent {
  // margin-top: -17px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #8e8e8e;
  display: flex;
  margin-right: 5px;
}

.yamlTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  padding-left: 12px;
  color: #000000;

  & .yamlCodeContainer {
    padding: 0 7px;
    margin-bottom: 11px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
  }
}
