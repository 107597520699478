@import "~styles/colors";
@import "~styles/typography";

.modalFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.content {
  margin-top: 38px;
}

.form {
  margin-top: 30px;
  .inputContainer {
    margin-top: 18px;
  }
}
