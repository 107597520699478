@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;
}

.field {
  max-width: 345px;
  margin-bottom: 20px;
}

.content {
  margin-top: 30px;
}
