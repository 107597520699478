@import "~styles/colors";
@import "~styles/typography";

.container {
  width: 90%;
  // &:hover {
  //   .tooltip {
  //     display: block;
  //   }
  // }

  // & .tooltip {
  //   width: max-content;
  //   max-width: 400px;
  //   position: absolute;
  //   bottom: 15px;
  //   left: 55%;
  //   display: none;
  //   padding: 8px 22px;

  //   background: $secondaryBackground;
  //   border: 1px solid $dividerColor;
  //   box-sizing: border-box;
  //   box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  //   border-radius: 8px;

  //   @include font($lightFont, 14px, 14px);
  //   color: $mainText;
  //   z-index: 99999;
  // }
}
