@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px 30px 43px;
}

.blocksContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
}

.block {
  max-width: 150px;
  display: flex;
  flex-direction: column;

  & > div {
    &:nth-child(1) {
      @include font($mainFont, 16px, 19px, 500);
      color: $primaryText;
      margin-bottom: 20px;
      text-transform: capitalize;
    }

    &:nth-child(2) {
      @include font($mainFont, 36px, 43px, 600);
      color: $primaryText;
      margin-bottom: 14px;
    }

    &:nth-child(3) {
      @include font($mainFont, 12px, 14px, 600);
      color: $primaryRed;
      margin-bottom: 10px;
    }

    &:nth-child(4) {
      @include font($mainFont, 12px, 14px, 500);
      color: $greyText;
    }
  }
}
