.PolicyDetails{
    width: 80%;
    display: flex;
    flex-direction: column;
    & .policy_data{
        align-items: baseline;
        width: 100%;
        display: flex;

        & .UpdatedTimeLabel{
            width:40%;
            display: flex;
         }
         & .VersionLabel{
             width:15%;
             display: flex;
             align-items: center;
         }
         & .selectorLabel{
             width:45%;
             display: flex;
         }
    }
}
.PolicyReview{
    width:20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}