@import "~styles/colors";
@import "~styles/typography";

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .stepsContainer {
    width: 420px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }

  .form {
    width: 550px;
    margin: 0 auto;
    padding-top: 46px;

    .inputContainer {
      margin-bottom: 44px;

      .labelWrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        .label {
          @include font($mainFont, 16px, 18px, 600);
        }

        .showSteps {
          cursor: pointer;
          @include font($mainFont, 12px, 18px, 400);
        }
      }
    }
    .btnContainer {
      display: flex;
      justify-content: end;
      column-gap: 21px;
      margin: 61px 0px;
    }
  }
}
