@import "~styles/colors";
@import "~styles/typography";
  
 .input {
    box-sizing: border-box;
    border: 1px solid $dividerColor;
    border-radius: 10px;
    @include font($mainFont, 16px, 20px);
    color: $mainText;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:focus,
  input:active {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }