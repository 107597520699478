@import "~styles/colors";
@import "~styles/typography";

.tls-modal {
  &-container {
    color: $mainText;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    &__title {
      padding-right: 20px;
      width: 500px;
      word-wrap: break-word;
    }
    &__more {
      position: relative;
      transition: 0.3s all ease-in-out;
      margin-right: 42px;
      &-btn {
        background: $secondaryBackground;
        border-radius: 10px;
        width: 40px;
        height: 40px;
      }
      & div {
        position: absolute;
        height: 20px;
      }
    }
  }
  &-tab-content {
    padding: 20px;
    background: $secondaryBackground;
    height: 300px;
    overflow-y: scroll;
    border-radius: 10px;
    white-space: pre-line;
    ul {
      margin: 0;
      padding: 0;
      counter-reset: counter;
      list-style: none;
      white-space: initial;
      li {
        @include font($lightFont, 1rem, 1.5);
        counter-increment: counter;
        &::before {
          content: counter(counter) ". ";
          font-weight: 600;
        }
        a {
          font-size: 14px;
        }
      }
    }
    h2,
    p {
      margin: 0;
    }
    pre {
      @include font($lightFont, 1rem, 1.5);
      color: $primaryText;
      margin: 0;
    }
    code {
      @include font($lightFont, 12px, 1);
      background: $greyText;
      border: 1px solid $darkText;
      color: $breadCrumbsText;
      padding: 0.5px 4px;
    }
  }
  &-divider-md {
    margin: 10px 0;
  }
  &-divider-sm {
    margin: 5px 0;
    width: 100%;
  }
  &-nav {
    display: inline-flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 12px;
    font-size: 14px;
    margin-bottom: 10px;
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      border-radius: 50%;
      &:disabled {
        opacity: 0.5;
      }
    }
    span {
      font-weight: 600;
    }
    &__right {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  &-details {
    display: flex;
    justify-content: space-between;
    gap: 14px;
    &__info {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      word-break: normal;
      overflow-wrap: anywhere;

      &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      &-asset {
        font-weight: 600;
        font-size: 14px;
        color: $greyText;
        margin: 0;
        span {
          color: $mainText;
          padding-left: 13px;
        }
        &-category {
          font-weight: 600;
          font-size: 14px;
          color: $greyText;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
          gap: 5px;
          &__tag {
            background: $mainText;
            color: $white;
            border-radius: 4px;
            padding: 10px 18px;
            text-align: center;
          }
        }
      }
    }
    &__form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      gap: 10px;
      background: $infoBackground;
      border-radius: 10px;
      &-action {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        &-container {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          width: 100%;
        }
        & > span {
          font-weight: 500;
          font-size: 14px;
        }
        &-field {
          width: 160px;
        }
        &-redirect {
          position: absolute;
          right: 11px;
        }
        &-switch {
          position: relative;
          width: 160px;
          & > label {
            position: absolute;
            top: 8px;
            right: 0;
            margin: 0;
          }
        }
        &-btn {
          display: flex;
          flex-direction: column;
          width: 100%;
          &-more {
            color: $black;
            background: $greyBackground;
            &:hover {
              color: $infoBackground;
              background: darken($greyBackground, 50%);
            }
          }
        }
      }
      &-link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 35px;
        border-radius: 10px;
        gap: 10px;

        width: 202px;
        height: 40px;
        margin: 10px auto auto auto;
        background: $primaryRed;
        color: $white;
        &:hover {
          background: $primaryRed;
          color: $white;
        }
      }
    }
  }
  &-tabs {
    margin-top: 20px;
  }
}
