@import "~styles/colors";
@import "~styles/typography";

.modal {
  height: 100%;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.content {
  margin-top: 38px;
}

.form {
  margin-top: 30px;
  .inputContainer {
    margin-top: 18px;
  }
}

.checkbox {
  margin-top: 18px;
  margin-left: 0;
}

.switch {
  margin-left: 10px;
}

.sub_title_row {
  display: flex;
  align-items: center;
  margin-top: 40px;
  .sub_title {
    @include font($boldFont, 18px, 16px);
    color: $mainText;
  }
}

.section {
  margin-top: 20px;
  background: #fbf8f8;
  padding: 20px;
  border-radius: 10px;
  .row {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: flex-end;
  }
  .priorityName {
    @include font($mediumFont, 14px, 16px);
    color: $mainText;
    margin-bottom: 15px;
  }

  .removeBtn {
    position: absolute;
    top: 10px;
    right: 0;
    cursor: pointer;
  }
}

.add {
  cursor: pointer;
  @include font($mediumFont, 16px, 18px);
  color: $primaryRed;
  margin-top: 20px;
  display: flex;
  align-items: center;
  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-color: $secondaryRed;
    border-radius: 5px;
    margin-right: 5px;
    align-self: center;
  }
}
.inline {
  display: flex;
  align-items: flex-end;
}
.create {
  .add {
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 7px;
    .icon {
      margin: 0;
    }
  }
}

.loader {
  position: relative;
  height: 90%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
