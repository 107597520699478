@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;
  margin-bottom: 1%;

  & .title {
    font-weight: 600;
  }
  & .filterRow {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    width: 50%;

    & .select {
      width: 180px;
      height: 40px;
    }
  }
}
