@import "~styles/colors";
@import "~styles/typography";

.filter {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #fff;
  padding: 25px 25px 25px 30px;
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  margin-bottom: 20px;
}

.datePicker {
  max-width: 250px;
  background: #fff;
}

.chartContainer {
  display: flex;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #fff;
  padding: 25px 25px 25px 30px;
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  margin-bottom: 0;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      width: 40px;
      height: 40px;
      background: #f2f5fa;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .iconBtn {
        transform: rotate(135deg);
      }
    }
  }
  .chart {
    height: 235px;
  }
}

.asset {
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  p {
    font-family: $lightFont !important;
    margin: 0;
  }
  :hover {
    font-family: $boldFont !important;
  }
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.row2 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}
