@import "~styles/colors";
@import "~styles/typography";

.container {
  width: 154px;
  height: 154px;
  text-align: center;
  color: $mainText;

  & .title {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin: 30px 0px 34px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .amount {
    font-size: 36px;
    font-weight: 600;
    line-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .blockTitle {
    @include font($mediumFont, 18px, 18px);
  }
}
