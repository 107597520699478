@import "~styles/colors";
@import "~styles/typography";

.container {
    padding: 15px;
  }
.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.outer{
    position: relative;
    margin-top: 0;
    & .container{
        display: flex;
        justify-content: start;
        border-left: 2px solid #dbdff0;
        margin-left: 30px;
        margin-bottom: 0;
        position: relative;
        & .icon {
            // width: 25%;
            // float: left;
            // height: 38px;
            // top: 17px;
            left: -13px;
            position: absolute;
            &-horline {
                width: 100%;
                position: absolute;
                left: 0;
            }
        }
        & .innercontainer{
            width: 100%;
            margin: 0px 0px 10px 30px;
            align-items: center;
            & .linehorizontal{
              margin-top: 25px;
              width: 30%;
              border-top: 2px solid #dbdff0;
              position: absolute;
              left: 0;
            }
        }
    }
}