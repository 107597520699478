@import "~styles/colors";
@import "~styles/typography";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.btnGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  height: 40px;
  padding: 0 24px;
  margin-left: auto;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
