.title {
  padding-bottom: 20px;
}

.titleContainer {
  padding: 10px 10px 0 20px;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding: 10px 25px 25px 25px;

  .dateContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  .dayBoxContainer {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: flex-start;
    width: 100%;
  }

  .daysContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }

  .selectContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  .heading {
    color: #2b3858;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
  }

  .datePicker {
    width: 200px;
    height: 50px;
  }
  .selectTime {
    width: 200px;
    height: 50px;
  }

  .selectedDays {
    color: #1e3687;
    border: 1px solid #1e3687;
  }

  .dayContainer {
    width: 50px;
    height: 50px;
    border: 1px solid #d9dfea;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #959eb5;
  }

  .summary {
    color: #959eb5;
    font-size: 14px;
    width: 100%;
    font-weight: 500;
  }

  .btnContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .cancelBtn {
    background-color: transparent;
    border: 1px solid #7b7b7b;
    color: #05147d;
    border-radius: 5px;
  }

  .saveBtn {
    border-radius: 5px;
  }

  .Btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
