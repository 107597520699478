@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;
  background: #ffff;
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  border-radius: 15px;
}

.form {
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    margin-bottom: 20px;
    margin-top: 30px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
