$black: #282828;
$white: #fff;
$dark: #000;

$background: #fdfdfd;
$secondaryBackground: #f2f5fa;
$infoBackground: #fbf8f8;

$darkText: #474a52;
$greyText: #959eb5;
$background: #f2f5fa;
$greyBackground: #bac2d2;

$mainText: #2b3858;
$primaryText: #404040;
$breadCrumbsText: #07090c;

$primaryRed: #d83e3c;
$secondaryRed: #f9e8e8;
$dividerColor: #d9dfea;
$tagBgColor: #f5f8ff;

$primaryBlue: #1e3687;
$secondaryBlue: #e8e9f9;
