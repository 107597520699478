.container {
  width: 100%;
}
.selectsGroup {
  display: grid;
  // grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  .select {
    width: 290px;
    padding-bottom: 25px;
    &:last-child {
      margin-right: 0;
    }
  }
  .filter_grid {
    gap: 20px;
    display: grid;
    grid-template-columns: auto 100%;
  }
}
