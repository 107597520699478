@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;

  & .form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & .row {
      display: grid;
      grid-template-columns: 1fr minmax(0, 1fr) 1fr;
      gap: 30px;
    }
  }

  & .title {
    margin-bottom: 20px;
  }

  & .searchContainer {
    width: 342px;
    @include font($mainFont, 14px, 16px, 400);
    margin-bottom: 30px;
  }

  & .search {
    background: #f3f5f9;
    padding: 9px 12px !important;
    border: none !important;
  }
}

.btnsContainer {
  display: flex;
  gap: 20px;
  margin-top: 10px;

  & .btn {
    height: 40px;
    padding: 0 35px;
  }

  & .btn:last-child {
    margin-left: auto;
  }
}
