@import "~styles/colors";
@import "~styles/typography";

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
}

.breadcrumbs {
  display: flex;
  justify-content: center;
}

.leftSide {
  display: flex;
  align-items: center;
  gap: 20px;

  .notifications {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    &.unread {
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        position: absolute;
        top: 1px;
        left: 1px;
        border-radius: 50%;
        background-color: $primaryRed;
      }
    }
  }

  .select {
    min-width: 200px;
    z-index: 999;
  }
  .name {
    display: flex;
    align-items: center;
    @include font($boldFont, 14px, 16px);
    color: $primaryText;
    margin-left: 20px;
    cursor: pointer;
    .icon {
      margin-left: 5px;
    }
  }
}

.notification {
  padding: 10px 20px 10px 25px;
  border-bottom: 1px solid $dividerColor;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: $background;
  }

  & > div:first-child {
    @include font($lightFont, 14px, 21px);
    color: $primaryText;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > div:last-child {
    @include font($lightFont, 12px, 18px);
    color: $greyText;
  }
}

.notification.unread {
  &::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    left: 10px;
    top: -55%;
    border-radius: 50%;
    background-color: $primaryRed;
  }
}

.notificationBtn,
.notificationNoData {
  display: flex;
  justify-content: center;
  padding: 15px;
  @include font($mainFont, 14px, 16px);
  color: $primaryText;
  cursor: pointer;
}
