@import "~styles/colors";
@import "~styles/typography";

.container {
    padding: 30px;
  }
.action {
  display: flex;
  flex-direction: column;
  background: $white;
  gap: 1rem;
  padding: 15px 0;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__filter {
      display: flex;
      gap: 10px;
      width: 73%;
      &-option {
        width: 200px;
      }
    }
  }
}