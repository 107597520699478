@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px 120px 38px 90px;
  margin: 50px 0px;
  & .help {
    background: #e9e9e9;
    /* border-radius: 13%; */
    padding: 2rem;
    margin-top: 1rem;
  }
}