.container {
  padding: 30px;

  & .searchRow {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
  }
  & .table {
    margin-top: 30px;
  }
}
.selectsGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  .select {
    min-width: 200px;
    &:last-child {
      margin-right: 0;
    }
  }
}
