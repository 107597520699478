.headingContainer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.container{
  padding:30px;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  .btnContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 8px;

    .cancelBtn {
      border: 1px solid #d9d9d9;
      background: #fff;
      color: #474748;
    }
    .cancelBtn:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
