@import "~styles/colors";
@import "~styles/typography";

.items{
  border-right: 1px solid #D0D5DD !important;
}

.additionalInfo{
  white-space: pre-wrap;
  padding-bottom: 10px;
  border-bottom: 2px solid #0000002e;
}

.rowMargin{
  margin-bottom: 0 !important;
}

.eachCell{
  outline: none !important;
}