@import "~styles/colors";
@import "~styles/typography";

#profile-menu {
  .MuiPaper-root {
    border-radius: 10px;
  }
  .MuiList-root {
    padding: 0;
  }
  .MuiMenuItem-root {
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
    span {
      @include font($mediumFont, 16px, 24px);
      color: $primaryText;
      margin-left: 5px;
    }
    &:first-child {
      border-bottom: 1px solid #edecf2;
    }
  }
}
