@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;
}

.input {
  padding: 8px 12px !important;
  font-size: 14px !important;
}
.code-editor {
  width: 100%;
}

.form-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  .row {
    display: flex;
    gap: 1rem;
    padding: 0;
  }
  .form {
    width: 100%;
  }
  .full-width {
    width: 100%;
  }
  .submitBtn {
    margin-left: auto;
  }
  .error-text-container {
    padding: 8px 0;
  }
  .policy-type__selector {
    width: 40% !important;
  }
}

.cancelBtn {
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #474748;
}
.cancelBtn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
