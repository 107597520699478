@import "~styles/colors";
@import "~styles/typography";

.content {
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  border-radius: 15px 15px 0px 0px;
}

.filters {
  padding: 30px;
  background: #ffff;
  border-radius: 15px 15px 0px 0px;
}

.searchRow {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.searchActions {
  display: flex;
  flex-direction: row;
}

.addToGroupBtn {
  margin-left: 10px;
  background: white;

  color: $primaryRed;

  & svg path {
    fill: $primaryRed;
  }

  &:hover {
    background: white;
  }
}

.deleteBtn {
  background: $mainText;

  &:hover {
    background: $breadCrumbsText;
  }

  margin-left: 10px;
}

.filterRow {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.searchContainer {
  width: 342px;
}

.search {
  background: #f3f5f9;
  padding: 9px 12px !important;
  border: none !important;
}

.selectsGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  .select {
    min-width: 200px;
    &:last-child {
      margin-right: 0;
    }
  }
}