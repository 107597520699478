@import "~styles/colors";
@import "~styles/typography";

.container{
  width: 95%;
  height: 92%;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  z-index: 11;
  border-radius: 10px;

  & .box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 2px 2px 8px #0000008a;
    padding: 4px;
    z-index: 111;
  }
  & .select{
    width: 70%;
  }

}
