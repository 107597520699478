@import "~styles/colors";
@import "~styles/typography";

.wrapper {
    position: relative;

    & .labelCont {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        & .label {
            @include font($mainFont, 14px, 16px);
            color: $mainText;
        }
    }

    & .noOptions {
        width: 100%;
        text-align: center;
        @include font($mainFont, 14px, 20px, 500);
        color: $mainText;
        padding: 8px;
    }

    & .container {
        width: 100%;
        min-height: 40px;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $dividerColor;
        border-radius: 8px;
        gap: 5px;
        padding: 7px;
        box-sizing: border-box;
    }

    & .tagscontainer {
        width: 100%;
        min-height: 40px;
        height: 160px;
        display: flex;
        // flex-wrap: wrap;
        flex-direction: column;
        border: 1px solid $dividerColor;
        border-radius: 8px;
        gap: 5px;
        padding: 7px 7px 7px 7px;
        box-sizing: border-box;
        overflow: scroll;
    }

    & .less {
        height: auto !important;
        overflow: hidden;
    }

    & .tag {
        width: 100%;
        height: 34px;
        // background: $secondaryBackground;
        border-radius: 4px;
        @include font($mainFont, 12px, 14px);
        color: #2B3858;
        display: flex;
        font-weight: 500;
        // justify-content: center;
        align-items: center;
        padding: 0 18px;
        margin: 2px;
        cursor: pointer;
        position: relative;
        // align-self: center;


        &:hover {
            background: $mainText;
            color: $white;

            & .removeBtn {
                display: block;
                color: $primaryRed;
            }
        }

        & .removeBtn {
            display: block;
            position: relative;
            width: 10px;
            margin-left: 10px;
            // top: -3px;
            // right: -3px;
            // transform: rotate(90deg);
            // line-height: 5px;
        }
    }

    & .tag.selected {
        background: $mainText;
        color: $white;
        width: fit-content;

        &:hover {
            background: $tagBgColor;
            color: $mainText;
        }
    }

    & .addBtn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        background-color: $tagBgColor;
        border-radius: 5px;
        margin-left: 5px;
        align-self: center;
    }

    & [aria-disabled="true"] {
        background-color: $greyBackground;
        opacity: 0.5;
    }

    & .modal {
        $height: fit-content;
        width: 100%;
        position: absolute;
        left: 0;
        box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
        background-color: $white;
        margin-top: 8px;
        z-index: 2;
    }

    & .modalholder {
        display: flex;
        flex-direction: column;
    }

    & .tagholder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 8px 0px 0px;
    }

    & .selectall {
        // background: $secondaryBackground;
        border-radius: 4px;
        color: $darkText;
        font-size: 14px;
        font-weight: 600;
        padding: 0px 0px 0px 18px;
        height: 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & .search {
        background: $secondaryBackground;
        border-radius: 4px;
        color: $darkText;
        font-size: 14px;
        padding: 5px 13px 5px 18px;
        height: 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & .searchbar {
        outline: none;
        border: none;
        width: 60%;
        color: rgb(17 24 39);
        border-radius: 8px;
        cursor: pointer;
        background-color: transparent;
    }

    & .displaynone {
        display: none;
    }
}

.required {
    & .label {
        &::after {
            content: "*";
            color: $primaryRed;
            font-weight: 600;
            margin-left: 2px;
        }
    }
}