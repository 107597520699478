@import "~styles/colors";
@import "~styles/typography";

.verified {
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.title {
  @include font($boldFont, 32px, 42px);
  color: $mainText;
  margin-bottom: 19px;
  align-self: center;
}

.description {
  @include font($mediumFont, 17px, 17px);
  color: #898d97;
  margin-bottom: 63px;
  text-align: center;
}

.button {
  margin-top: -7px;
  align-self: center;
  margin-bottom: 172px;
  background-color: #360fd5;
  color: white;
  height: 3rem;
  font-size: 1.125rem;
  border-radius: 0.375rem;
}

.button:hover {
  background-color: #1f087b;
}
