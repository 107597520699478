@import "~styles/colors";
@import "~styles/typography";

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  // margin-left: 171px;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.bg {
  position: relative;
  display: flex;
  flex: 1;
  height: 100vh;
}

.logo {
  position: absolute;
  top: 40%;
  right: 30%;
}

.input {
  height: 55px !important;
  border-radius: 6px !important;
}
.input:focus {
  border: 1px solid #1565d8;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 58px;
}

.button {
  margin-top: 20px;
  background-color: #3200c1;
  width: 11rem;
  height: 3rem;
  font-size: 1rem;
  border-radius: 0.375rem;
  margin-top: 25px;
  color: white;
  position: relative;
}

.button:hover {
  background-color: #1f087b;
}

.title {
  @include font($boldFont, 34px, 42px);
  color: $mainText;
  // margin-bottom: 20px;
  width: 100%;
  text-align: left;
  margin-top: -28px;
}

.authCard {
  width: 32rem;
  height: 47rem;
  background-color: #1f087b;
  border-top-left-radius: 0.9375rem;
  border-bottom-left-radius: 0.9375rem;
  padding-inline-start: 4rem;
  padding-inline-end: 4rem;
  padding-bottom: 8rem;
  padding-top: 16rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  flex-direction: column;
}

.formSignIn {
  width: 40rem;
  height: 47rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background-color: white;
  border-top-right-radius: 0.9375rem;
  border-bottom-right-radius: 0.9375rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
}

.signUpBtn {
  width: 11rem;
  height: 3rem;
  color: black;
  background-color: white;
  border-radius: 0.375rem;
  font-size: 1.125rem;
}

.signUpBtn:hover {
  background-color: #3200c1;
  color: white;
}

.createPassword {
  height: 4rem !important;
  border-radius: 6px;
}

.createpassword {
  height: 4rem !important;
}
