@import "~styles/colors";
@import "~styles/typography";

.container {
  width: 100%;
  margin-bottom: 15px;
}

.inputLabel {
  @include font($mainFont, 14px, 16px);
  color: $mainText;
  bottom: 7px;
}

.weekDayBlockBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 18px;
}

.weekDayButton {
  width: 50px;
  height: 50px;

  background: #ffffff;
  border: 1px solid $greyText;
  box-sizing: border-box;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  @include font($mediumFont, 16px, 19px);
  color: $greyText;

  cursor: pointer;
}

.weekDayButtonChecked {
  border: 1px solid $primaryBlue;
  color: $primaryBlue;
}

.error {
  height: 20px;
  @include font($lightFont, 12px, 16px);
  color: $primaryRed;
  margin-top: 12px;
}

.required {
  & .inputLabel {
    &::after {
      content: "*";
      color: $primaryRed;
      font-weight: 600;
      margin-left: 2px;
    }
  }
}
