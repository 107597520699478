@import "~styles/colors";
@import "~styles/typography";

.primaryNode {
  & .react-flow__node {
    justify-items: center !important;
    align-items: center;
  }

  & .podimg {
    width: 50px !important;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-65%, -65%);
  }
  & .exp {
    padding: 1px;
    width: 18px;
    z-index: 1000;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    position: absolute;
    border: 1.5px solid gray;
    background-color: white;
    border-radius: 50%;
    top: 0%;
    right: 5%;
  }
  & .childName {
    position: absolute;
    bottom: 0%;
    left: 0%;
    transform: translate(-15%, 100%);
    width: 100px;
    word-break: break-all;
  }
}

.primarySubNode {
  & .node {
    z-index: 0 !important;
    cursor: none !important;
  }
  & .subImg {
    height: 30px !important;
    width: 30px !important;
    z-index: 10 !important;
    display: flex;
  }
  & .subImg:hover {
    cursor: pointer !important;
  }

  & .tooltip {
    display: flex !important;
    border-left: "2px solid blue" !important;
    width: 300px !important;
    height: 100px !important;
    color: black !important;
    border-radius: 5px !important;
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  & .node-top-pri-sub {
    z-index: 1000;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    position: absolute;
    border: 1.5px solid gray;
    background-color: white;
    border-radius: 50%;
    top: -7%;
    right: 0%;
  }

  & .childName {
    position: absolute;
    bottom: 0%;
    left: 0%;
    transform: translate(-15%, 100%);
    width: 100px;
    word-break: break-all;
  }
}

.secondaryNode {
  & .img {
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
  }

  & .react-flow__node {
    justify-items: center !important;
    align-items: center;
  }

  & .main-core {
    content: "";
    position: absolute;
    top: -7px;
    left: 65%;
    height: 25px;
    width: 25px;
    transform: translate(-50%, 0);
    background: #ffffff;
    z-index: 1000;
    line-height: 1;
    border-radius: 50%;
    color: #fff;
    font-size: 9px;
    border: 1px solid #828282;
  }

  & .collapse-icon {
    position: absolute;
    top: 7%;
    left: 7%;
  }

  & .podimg {
    width: 50px !important;
    height: 50px;
    position: absolute;
    top: 18%;
    left: 18%;
  }
  & .exp {
    width: 18px;
    z-index: 1000;
    height: 18px;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    position: absolute;
    border: 1.5px solid gray;
    background-color: white;
    border-radius: 50%;
    bottom: 88%;
  }

  & .secondaryHeadBg {
    background: #fff;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  & .subtooltip {
    display: flex !important;
    border-left: "2px solid blue" !important;
    width: 24rem !important;
    height: 8rem !important;
    color: black !important;
    border-radius: 5px !important;
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  & .toolTipBox {
    text-align: left;
    padding-bottom: 10px;
  }

  & .expIcon {
    padding: 0px 3px;
    width: 30px;
    z-index: 1000;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    position: absolute;
    border: 1.5px solid gray;
    background-color: white;
    border-radius: 50%;
    top: 14%;
    right: 5%;
  }

  & .customToolTipTop {
    background: rgba(0, 0, 0, 0.671);
    white-space: nowrap;
    color: #fff;
    top: -60%;
    position: absolute;
    left: -55%;
    padding: 5px;
    border-radius: 5px;
    opacity: 1 !important;
  }
  & .desc {
    border-top: 3px solid #cddbfe;
  }
}

.secondarySubNode {
  & .node {
    z-index: 0 !important;
    cursor: none !important;
  }
  & .img {
    height: 35px;
    width: 35px;
    display: flex;
    z-index: 10 !important;
  }
  & .img:hover {
    cursor: pointer !important;
  }

  & .tooltip {
    display: flex !important;
    border-left: "2px solid blue" !important;
    width: 300px !important;
    height: 100px !important;
    color: black !important;
    border-radius: 5px !important;
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  & .node-top {
    z-index: 1000;
    width: 18px;
    display: flex;
    height: 18px;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    position: absolute;
    border: 1.5px solid gray;
    background-color: white;
    border-radius: 50%;
    bottom: 84%;
    left: 48%;
  }
  & .customToolTipRight {
    background: rgba(0, 0, 0, 0.671);
    color: #fff;
    top: 30%;
    position: absolute;
    left: 140%;
    padding: 5px;
    border-radius: 5px;
    opacity: 1 !important;
    white-space: nowrap;
  }
  & .customToolTipLeft {
    background: rgba(0, 0, 0, 0.671);
    color: #fff;
    top: 30%;
    position: absolute;
    left: -250%;
    padding: 5px;
    border-radius: 5px;
    opacity: 1 !important;
    white-space: nowrap;
  }

  & .sourceCommand {
    color: #326ce5;
  }
}

.secondarySubNodeOpacity {
  opacity: 0.2;
}
