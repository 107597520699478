@import "~styles/colors";
@import "~styles/typography";

.container {
  margin-bottom: 30px;
  box-shadow: 0px 0px 26px rgba(161, 165, 180, 0.08);
  border-radius: 15px;
  padding: 30px;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 30px;
}

.sub_title {
  @include font($boldFont, 16px, 13px);
  color: #8490a5;
}

.inputsCont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 30px;
}

.checkbox {
  display: block;
  margin-top: 20px;
  margin-left: 0;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box {
  background: #f2f5fa;
  border-radius: 10px;
  padding: 20px 24px;
  display: flex;
  .boxContent {
    flex: 1;
    .name {
      @include font($boldFont, 18px, 20px);
      color: $mainText;
      margin-bottom: 18px;
    }
    .row {
      justify-content: flex-start;
      margin-bottom: 10px;
      .title {
        flex: 0.5;
        @include font($boldFont, 14px, 16px);
        color: $greyText;
      }
      .description {
        flex: 1;
        @include font($mainFont, 14px, 16px);
        color: $greyText;
      }
    }
  }
  .buttonGroup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .button {
      cursor: pointer;
    }
  }
}

.buttonFooter {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}
