@import "~styles/colors";
@import "~styles/typography";

.chart {
  position: relative;
  transform: scale(-1, 1);
}

.wrapper {
  position: relative;
}

.list {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  // transform: scale(-1, 1);
  width: 50%;
  .row {
    margin-bottom: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    .color {
      width: 14px;
      height: 14px;
      border-radius: 4px;
    }
    .name {
      @include font($mainFont, 12px, 13px);
      color: $mainText;
      white-space: nowrap;
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.list2 {
  position: relative;
}

.list3 {
  position: relative;
}
