@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;

  & .searchRow {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-top: 20px;

    & .searchContainer {
      width: 342px;
    }

    & .search {
      background: #f3f5f9;
      padding: 9px 12px !important;
      border: none !important;
    }

    & .btn {
      height: 40px;
      padding: 0 25px;
    }
  }

  & .filterRow {
    display: flex;
    gap: 20px;
    margin-top: 20px;

    & .select {
      width: 180px;
      height: 40px;
    }

    & .datePicker {
      width: 180px;
      height: 40px;
    }
  }

  & .table {
    margin-top: 30px;
  }

  .buttonGroup {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
