@import "~styles/colors";
@import "~styles/typography";

.panel {
  position: relative;
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  background: #fff;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 30px;
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .panelHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .panelContent {
    margin-top: 18px;
    .panelGrid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 30px;
      margin-bottom: 20px;
    }
    .saveConfigButton {
      position: absolute;
      right: 30px;
      top: 30px;
      width: fit-content;
    }
  }

  .panelConfig {
    margin-top: 20px;
    .panelGrid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 30px;
    }
  }

  .panelRow {
    display: flex;
  }

  .selectConfigText {
    display: flex;
    align-items: center;
    margin-right: 20px;
    @include font($mediumFont, 14px, 16px);
    color: #959eb5;
    span {
      @include font($boldFont, 14px, 16px);
      color: $mainText;
      margin-left: 5px;
    }
  }

  .input {
    padding: 8px 12px !important;
    font-size: 14px;
  }

  .select {
    width: 162px;
    min-width: 162px;
    margin-right: 15px;
  }
  .buttonGroup {
    margin-top: 30px;
  }
  .button {
    padding: 8px 24px;
    margin-right: 18px;
    &.right {
      margin-left: auto;
      margin-right: 0;
    }
    &.close {
      margin-right: 10px;
    }
  }
}

.content {
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  border-radius: 15px 15px 0px 0px;
}

.filters {
  padding: 30px;
  background: #ffff;
  border-radius: 15px 15px 0px 0px;
}

.searchContainer {
  width: 342px;
}

.search {
  background: #f3f5f9;
  padding: 9px 12px !important;
  border: none !important;
}

.filterRow {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.selectsGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  .select {
    min-width: 200px;
    // margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.buttonGroup {
  display: flex;
  .actionButton {
    margin-left: 16px;
  }
}

.loader {
  position: absolute;
  z-index: 11;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.columnHeader {
  font-weight: 500;
}
