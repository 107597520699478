@import "~styles/colors";
@import "~styles/typography";

.pageBody {
  background: white;
  box-shadow: 0 0 26px rgba(161, 165, 180, 0.08);
  border-radius: 15px;

  padding: 30px 30px 38px 30px;
}

.pageTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 30px;
}

.dynControlsBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.dynControlBox2 {
  box-shadow: 0 0 26px rgba(161, 165, 180, 0.08);
  border-radius: 15px;
  background: #f8f8fb;

  padding: 30px 30px 38px 30px;
  .row {
    display: flex;
    gap: 10px;
  }
}

.dynControl {
  width: 346px;
  margin-bottom: 20px;
}

.scheduleBox {
  width: 558px;
  margin-bottom: 30px;
}

.tagsGroupBox {
  display: flex;
  flex-direction: row;

  margin-bottom: 30px;
}

.tagsGroupItem {
  width: 346px;
  margin-right: 30px;
}

.targetTypeGroupBox {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 33px;
}

.targetTypeGroupItem {
  width: 346px;

  margin-right: 30px;
  margin-bottom: 30px;
}
.createTokenButton {
  float: right;
}

.buttonsGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.buttonsGroupItem {
  padding: 12px 35px;
}

.addBtnBody {
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
}

.addBtnIcon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  background-color: $secondaryBlue;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  & path {
    fill: $primaryBlue;
  }
}

.addBtnLabel {
  @include font($mediumFont, 14px, 16px);
  color: $primaryBlue;
}
