@import "~styles/colors";
@import "~styles/typography";

.label {
  position: initial;
  @include font($mainFont, 14px, 16px);
  color: $mainText;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
