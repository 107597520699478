@import "~styles/colors";
@import "~styles/typography";

.container {
  width: 100%;
}

.labelCont {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  position: initial;
  @include font($mainFont, 14px, 16px);
  color: $mainText;
}

.error {
  @include font($mainFont, 14px, 16px);
  color: $primaryRed;
  margin-top: 10px;
}

.required {
  & .label {
    &::after {
      content: "*";
      color: $primaryRed;
      font-weight: 600;
      margin-left: 2px;
    }
  }
}
