@import "~styles/colors";
@import "~styles/typography";

.container {
  width: 100%;
}

.inputLabel {
  @include font($mainFont, 14px, 16px);
  color: $mainText;
  bottom: 7px;
}

.dateCtrl {
  width: 100%;
}

.inputBox {
  display: flex;
  align-items: center;
}

.inputCtrl {
  width: 100%;
  box-sizing: border-box;
  padding: 7px 17px;
  border: 1px solid $dividerColor;
  border-radius: 10px;
  @include font($mainFont, 16px, 20px);
  color: $mainText;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

.error {
  height: 20px;
  @include font($lightFont, 12px, 16px);
  color: $primaryRed;
  margin-top: 12px;
}

.required {
  & .inputLabel {
    &::after {
      content: "*";
      color: $primaryRed;
      font-weight: 600;
      margin-left: 2px;
    }
  }
}
