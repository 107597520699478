.w-tc-editor pre[class*="language-"] .token.number,
.token.selector {
  color: #b4b4b4 !important;
}

.w-tc-editor pre[class*="language-"] .token.attr-value {
  color: #3d931a !important;
}

.w-tc-editor pre[class*="language-"] .token.attr-name {
  color: #c5ad17 !important;
}

.w-tc-editor pre[class*="language-"] .token.tag {
  color: #da1f1f !important;
}

.w-tc-editor pre[class*="language-"] .token.string {
  color: #3a9ee3 !important;
}
