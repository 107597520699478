@import "~styles/typography";

.container {
  // padding: 21px 16px;
  padding: 30px;


  .controls {
    display: flex;
    justify-content: space-between;
    // padding-bottom: 18px;
    margin: 20px 0 30px;
  }
}

.button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #0066ff;
  color: #0066ff;
  @include font($mainFont, 14px, 16px, 500);

  &:hover {
    background-color: rgba(0, 102, 255, 0.1);
  }
}

.scanContainer {
  display: flex;
  column-gap: 8px;
}
