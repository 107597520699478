@import "~styles/colors";
@import "~styles/typography";


.container{
  margin-top: 40px;

  & .containerBtn{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
  }

  & .integrationBtn{
    width: 200px;
    position: relative;
    top: -12px;
  }
}