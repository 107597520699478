@import "~styles/colors";
@import "~styles/typography";

.labelCont {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & .label {
    position: initial;
    @include font($mainFont, 14px, 16px);
    color: $mainText;
  }
}
