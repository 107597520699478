@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;
}

.policy-metadata {
  padding: 1rem 0;
}

.policy-yaml {
  margin-top: 16px;
}

.input-field {
  font-size: 14px !important;
}

.editor_action_group {
  display: flex;
  & button {
    font-size: 14px;
    & svg {
      margin-right: 4px;
    }
  }
}

.banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
