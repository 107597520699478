@import "~styles/colors";
@import "~styles/typography";

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;

  .btnsContainer {
    display: flex;
    gap: 20px;

    .btn {
      height: 40px;
      padding: 0 24px;
    }

    .delete {
      & svg {
        margin-left: 10px;
      }
    }
  }
}
