@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;
}

// Details

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 30px;

  &.row2fr {
    grid-template-columns: 1fr 2fr;
  }

  & .innerColumn {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  & .block {
    & > div:first-child {
      @include font($mediumFont, 14px, 16px);
      color: $mainText;
      margin-bottom: 15px;
    }

    & > div:nth-child(2) {
      @include font($mediumFont, 14px, 19px, 600);
      color: $mainText;
    }
  }

  & .block.list {
    & > div:first-child {
      @include font($mediumFont, 14px, 16px);
      color: $mainText;
      margin-bottom: 15px;
      border-bottom: none;
    }

    & > div {
      @include font($mediumFont, 16px, 30px);
      color: $mainText;
      border-bottom: 1px solid $dividerColor;
    }
  }

  & .innerBlocks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    & > div {
      & > div:first-child {
        @include font($mediumFont, 14px, 16px);
        color: $mainText;
        margin-bottom: 15px;
      }

      & > div:nth-child(2) {
        @include font($mediumFont, 14px, 19px, 600);
        color: $mainText;
      }
    }
  }
}

.related_obj {
  .related_obj_list {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;
  }
  .subTitlte {
    @include font($mediumFont, 14px, 16px);
    color: $mainText;
    margin-bottom: 10px;
  }
  .item {
    cursor: pointer;
    color: $mainText;
    margin-top: 5px;
    &:hover {
      background: $dividerColor;
    }
  }
}

.textarea {
  height: 135px;

  & textarea {
    height: 135px !important;
  }
}

.btnsContainer {
  display: flex;
  gap: 20px;
  margin-top: 30px;

  & .btn {
    height: 40px;
    padding: 0 35px;
  }
}

.link {
  color: inherit;
  text-decoration: none;
}

// Comments
.addBtnContainer {
  display: flex;
  justify-content: flex-end;

  .btn {
    height: 40px;
    padding: 0 35px;
    margin-bottom: 30px;
    margin-left: auto;
  }
}

.noData {
  @include font($mediumFont, 14px, 16px);
  color: $greyText;
  text-align: center;
}

.tile {
  border-radius: 10px;
  background-color: $secondaryBackground;
  padding: 20px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  & > div:first-child {
    @include font($mediumFont, 12px, 14px);
    color: $greyText;
    margin-bottom: 5px;
  }

  & > div:last-child {
    @include font($mediumFont, 14px, 21px);
    color: $mainText;
  }
}
