@import "~styles/colors";
@import "~styles/typography";

.title {
  padding-bottom: 20px;
}

.pointsContainer {
  padding: 20px 0px 20px 5px;

  .point {
    color: $darkText;
    @include font($mainFont, 18px, 28px, 400);

    &::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #858585;
      transform: translate(-5px, -3px);
    }
  }
}

.label {
  @include font($mainFont, 18px, 18px, 400);
  .bold {
    @include font($mainFont, 18px, 18px, 600);
  }
}

.inputContainer {
  margin: 20px 0px 50px;
}

.btnContainer {
  display: flex;
  justify-content: end;
  column-gap: 21px;
}
