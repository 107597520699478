.container {
  padding-top: 23px;

  .stepperWrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
    .stepper {
      width: 350px;
    }
  }

  .tabPanel {
    display: flex;
    justify-content: center;

    .selectTypePage {
      display: flex;
      flex-direction: column;
      row-gap: 35px;
      padding-top: 47px;
      width: 512px;
    }
    .btnContainer {
      display: flex;
      justify-content: end;
      column-gap: 21px;
      margin: 26px 0px 61px;
    }
  }
}
