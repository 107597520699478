.table_content {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  gap: 15px;
  & .text_ellipsis {
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  & .sub_titles {
    font-size: 14px !important;
  }
}
.top_text {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.color_line{
  border: none;
  height: 5px;
}
.information_button {
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #FFF;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background: #118ab2;
  padding: 10px 15px 10px 15px;
  width: auto;
}
.low_button{
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #FFF;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background: #249d6f;
  padding: 10px 15px 10px 15px;
  width: auto;
}
.medium_button {
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #FFF;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background: #e6e958;
  padding: 10px 15px 10px 15px;
  width: auto;
}
.high_button {
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #FFF;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background: #f47d57;
  padding: 10px 15px 10px 15px;
  width: auto;
}
.critical_button {
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #FFF;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background: #ac0a0a;
  padding: 10px 15px 10px 15px;
  width: auto;
}