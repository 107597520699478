@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;
}

.searchRow {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 30px;
}

.btnsContainer {
  display: flex;
  gap: 20px;

  & .btn {
    height: 40px;
    padding: 0 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}
