@import "~styles/colors";
@import "~styles/typography";

.linksContainer {
  & li {
    @include font($mainFont, 12px, 14px);
  }
}

.link {
  text-transform: capitalize;
  color: $breadCrumbsText;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.current {
  pointer-events: none;
}

.separator {
  transform: rotate(-90deg);
}
