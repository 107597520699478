@import "~styles/colors";
@import "~styles/typography";

.titleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stepTitle {
  @include font($mediumFont, 16px, 19px);
  color: $primaryBlue;
  margin-left: 16px;
  font-weight: 600;
}

.select {
  margin-top: 30px;
  margin-bottom: 30px;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
}

.form {
  margin-top: 30px;
}

.formInput {
  margin-bottom: 20px;
}

.dynControlBox2 {
  box-shadow: 0 0 26px rgba(161, 165, 180, 0.08);
  border-radius: 15px;
  background: #f8f8fb;
  margin-bottom: 20px;

  padding: 30px 30px 38px 30px;
  .row {
    display: flex;
    gap: 10px;
  }
}

.addBtnBody {
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
}

.addBtnIcon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  background-color: $secondaryBlue;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  & path {
    fill: $primaryBlue;
  }
}

.addBtnLabel {
  @include font($mediumFont, 14px, 16px);
  color: $primaryBlue;
}
