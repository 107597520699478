@import "~styles/colors";
@import "~styles/typography";

.title {
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  padding: 30px;
}

.content {
  padding: 30px;

  & .row {
    display: flex;
    margin-bottom: 25px;

    & > div:first-child {
      width: 100px;
      @include font($mainFont, 12px, 14px);
      color: $greyText;
    }

    & > div:last-child {
      @include font($mainFont, 14px, 18px);
      color: $mainText;
    }
  }
}
