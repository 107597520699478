@import "~styles/colors";
@import "~styles/typography";

.listBlock {
  background: #FFFFFF;
  border: 1px solid #D9DFEA;
  box-sizing: border-box;
  border-radius: 10px;

  width: 480px;

  padding: 10px;
  margin: 40px 0;

  @include font($mediumFont, 14px, 150%);
  color: $mainText;
}

.buttonBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}