.json-inspector,
.json-inspector__selection {
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

.json-inspector__leaf {
  padding-left: 10px;
}

.json-inspector__line {
  display: block;
  position: relative;
  cursor: default;
}

.json-inspector__line:after {
  content: '';
  position: absolute;
  top: 0;
  left: -200px;
  right: -50px;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
}

.json-inspector__line:hover {
  background: #ffd992 !important;
}

.json-inspector__leaf_composite {
  cursor: pointer;
}
.json-inspector__line {
  cursor: pointer;
}

.json-inspector__radio{
  display: none !important;
}
.json-inspector__flatpath {
  display: none !important;
}

.json-inspector__value {
  margin-left: 5px;
}

.json-inspector__search {
  display: none;
}

.json-inspector__key {
  color: #2b2b2c;
}

.json-inspector__value_helper,
.json-inspector__value_null,
.json-inspector__not-found {
  color: #b0b0b0;
}

.json-inspector__value_string {
  color: #121f9c;
}
.json-inspector__value_boolean {
  color: #75b5aa;
}
.json-inspector__value_number {
  color: #d28445;
}

.json-inspector__hl {
  background: #ff0;
  box-shadow: 0 -1px 0 2px #ff0;
  border-radius: 2px;
}

.json-inspector__show-original {
  display: inline-block;
  padding: 0 6px;
  color: #666;
  cursor: pointer;
}

.json-inspector__show-original:hover {
  color: #111;
}

.json-inspector__show-original:before {
  content: '⥂';
}

.json-inspector__show-original:hover:after {
  content: ' expand'
}
.json-inspector{
  padding: 10px 10px;
  border: 2px solid rgb(217, 224, 231);
}
.raw-logs {
  font-size: 14px !important;
  position: relative;
  top: 10px;
}