@import "~styles/colors";

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  height: 30px;
  border-radius: 50%;

  &:hover {
    background-color: $greyBackground;
  }
}
