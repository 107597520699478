.chartContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 21px;
}

.chartLegend {
  font-weight: 600;
  font-size: 24px;
  color: #2b3858;
  line-height: 28px;
  margin-top: 10px;
  margin-bottom: 20px;
}
