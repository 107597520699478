.loaderContainer {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  margin-bottom: 15px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 34px;
    height: 34px;
    margin: 8px;
    border: 3px solid #1e1e1e;
    border-radius: 50px;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #1e1e1e transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.4s;
  }
  div:nth-child(2) {
    animation-delay: -0.5s;
  }
  div:nth-child(3) {
    animation-delay: -0.2s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
