@import "~styles/colors";
@import "~styles/typography";

.container {
  position: relative;

  & .labelCont {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  & .label {
    position: initial;
    @include font($mainFont, 14px, 16px);
    color: $mainText;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid $dividerColor;
    border-radius: 10px;
    @include font($mainFont, 16px, 20px);
    color: $mainText;
    background: #fff;
  }

  & input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:focus,
  input:active {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  .error {
    border-color: $primaryRed;
    color: $primaryRed;
  }

  .error-text {
    margin-top: 10px;
    @include font($mainFont, 14px, 16px);
    color: $primaryRed;
  }

  .sub-text {
    margin-top: 10px;
    @include font($mainFont, 14px, 16px);
    color: $mainText;
  }
}

.required {
  & .label {
    &::after {
      content: "*";
      color: $primaryRed;
      font-weight: 600;
      margin-left: 2px;
    }
  }
}
