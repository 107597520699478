@import "~styles/colors";
@import "~styles/typography";

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 220px;
  max-height: 300px;
  height: 100%;
  background: $white;
  border: 1px solid $primaryRed;
  border-radius: 10px;

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    list-style: none;
    gap: 4px;
    font-weight: bold;
    padding: 0 10px;
    > li {
      &::after {
        content: ",";
      }
      &:last-child::after {
        content: ".";
      }
    }
  }

  button {
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
