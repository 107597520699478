@import "~styles/colors";
@import "~styles/typography";

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  // margin-left: 171px;
  flex-direction: column;
  gap:10px
}

.bg {
  position: relative;
  display: flex;
  flex: 1;
  height: 100vh;
}

.logo {
  position: absolute;
  top: 40%;
  right: 30%;
}

.input {
  // margin-bottom: 20px;
  height: 70px !important;
}

.inputpassword{
  height: 70px !important;
  z-index: -1;
}

.inputPassword {
  border-radius: 10px !important;
  height: 70px !important;
  z-index: -1;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  z-index: 1;
}

.button {
  margin-top: 32px;
  background-color: #3200C1;
  width: 11rem;
  height: 3rem;
  font-size: 1.125rem;
  border-radius: 0.375rem;
  margin-top: -15px;
}

.forgetPassword{
  color: rgba(0,0,0,0.6);
  width: 100%;
  text-align: right;
  cursor: pointer;
}

.button:hover{
  background-color: #1F087B;
}

.title {
  @include font($boldFont, 34px, 42px);
  color: $mainText;
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
}

.authCard {
  width: 32rem;
  height: 38rem;
  background-color: #1F087B;
  border-top-left-radius: 0.9375rem;
  border-bottom-left-radius: 0.9375rem;
  padding-inline-start: 4rem;
  padding-inline-end: 4rem;
  padding-bottom: 8rem;
  padding-top: 15rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  flex-direction: column
}

.formSignIn{
  width:40rem;
  height: 38rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background-color: white;
  border-top-right-radius: 0.9375rem;
  border-bottom-right-radius: 0.9375rem;
  border: 1px solid rgba(0,0,0,0.1);
  padding-top: 4rem;
  padding-bottom: 3.5rem;
  padding-inline-start: 4rem;
  padding-inline-end: 4rem;
}

.signUpBtn{
  width: 11rem;
  height: 3rem;
  color: black;
  background-color: white;
  border-radius: 0.375rem;
  font-size: 1.125rem;
}

.signUpBtn:hover{
  background-color: #3200C1;
  color: white;
}
