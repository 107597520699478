@import "~styles/colors";
@import "~styles/typography";

.status {
  width: fit-content;
  padding: 3px 9px;
  @include font($boldFont, 14px, 16px);
  color: $white;
  border-radius: 3px;
  background: #c4c4c4;
  &.active {
    background: #86c28c;
  }
}
