@import "~styles/colors";
@import "~styles/typography";

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.searchTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 25px 25px 30px;
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  margin-bottom: 0;
}

.addBtn {
  width: 95px;
}
