@import "~styles/colors";
@import "~styles/typography";

.modal-cols {
  display: flex;
  justify-content: space-between;
  height: 60vh;
  overflow-y: scroll;
  margin-top: 2rem;
  &:last-child {
    padding-right: 2rem;
  }
  &__left-col,
  &__right-col {
    gap: 2rem;
    display: flex;
    flex-direction: column;
    &-title {
      text-transform: capitalize;
      text-decoration: underline;
    }
    &-list {
      margin: 0;
      padding: 0;
      list-style: none;
      li > button {
        background: none;
        border: none;
        cursor: pointer;
        @include font($mediumFont, 16px, 16px);
        color: $mainText;
        &:hover {
          transition: 0.1s color linear;
          color: #83b0fd;
        }
      }
    }
  }
  &__rest-col {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      text-transform: capitalize;
      text-decoration: underline;
    }
    &-list {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  &__right-col-list {
    text-align: center;
  }
}

.modal-create {
  display: flex;
  flex-direction: column;
  &-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    &__file {
      margin: 0;
      padding: 10px 0;
      @include font($lightFont, 14px, 16px);
      span {
        flex: 0 0 auto;
        @include font($boldFont, 14px, 16px);
        color: $mainText;
        margin-right: 5px;
      }
    }
    &__submit {
      width: 300px;
      align-self: end;
    }
  }
}
