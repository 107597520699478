@import "~styles/colors";
@import "~styles/typography";

.container {
  max-height: 85vh;
  overflow: auto;
  overflow-y: auto;
  margin-top: 20px;
  padding-right: 20px;
}

.mb_20 {
  margin-bottom: 20px;
}

.title {
  margin: 20px 0;
}
