@import "~styles/colors";
@import "~styles/typography";

.scheduleEditBody {
  background: $secondaryRed;
  border-radius: 10px;
  padding: 30px;
}

.inputsBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blockInput {
  width: 205px;
  margin-bottom: 30px;
}

.summaryTitle {
  @include font($mediumFont, 16px, 19px);
  color: $primaryText;

  margin-top: 20px;
  margin-bottom: 10px;
}

.summary {
  @include font($mediumFont, 14px, 16px);
  color: $greyText;
}