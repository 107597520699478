.footer {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 3.5rem;
  background: #021040;
  color: white;

  & .footerContainer {
    padding-left: -2px;
  }

  & .footerContent {
    font-weight: 500;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    padding-left: 50px;
    display: flex;
    gap: 0.2rem;
  }
}
