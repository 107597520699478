@import "~styles/colors";
@import "~styles/typography";

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
}

.logo {
  position: absolute;
  top: 40%;
  right: 30%;
}

.cards {
  position: absolute;
  width: 100%;
  max-width: 500px;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  img {
    width: 100%;
  }
  .logo {
    top: -40px;
    left: 0;
    position: absolute;
    width: fit-content;
    z-index: 1;
  }
}

.button {
  background-color: #05147d;
  width: 180px;
  height: 51.48px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  color: white;
  margin-top: 10px;
}

.button:hover {
  background-color: #010b59;
}

.bg {
  position: relative;
  display: flex;
  flex: 1;
  height: 100vh;
}

.input {
  width: 30rem !important;
  height: 4rem !important;
  border-radius: 6px;
}

.input:focus {
  border: 1px solid #1565d8;
}

.inputcode {
  height: 4rem !important;
}

.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  box-shadow: 0px 17px 35px 0px #00000012;
  border-radius: 20px;
  width: 42.313rem;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 38rem;
  zoom: 0.8;
  margin-bottom: 15px;
}

.form {
  width: 410px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.title {
  color: black;
  font-size: 2.125rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.description {
  font-size: 1.1rem;
  font-weight: 400;
  color: #8692a6;
  margin-bottom: 30px;
  text-align: center;
  width: 70%;
}

@media (max-width: 540px) {
  .formContainer {
    width: 35rem;
  }
}

@media (max-width: 480px) {
  .formContainer {
    box-shadow: 0px 17px 35px 0px #ffffff12;
  }
}
