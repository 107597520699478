@import "~styles/colors";
@import "~styles/typography";

.controls {
  display: flex;
  flex-direction: row;
  padding: 30px;
  justify-content: space-between;

  & .searchContainer {
    width: 342px;
    @include font($mainFont, 14px, 16px, 400);
  }

  & .search {
    background: #f3f5f9;
    padding: 9px 12px !important;
    border: none !important;
  }

  .btnsContainer {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    .btn {
      height: 40px;
      padding: 0 24px;
    }

    .delete {
      background-color: $greyBackground;

      &:hover {
        background: rgba($color: #92969c, $alpha: 1);
      }

      & svg {
        margin-left: 10px;
      }
    }
  }
  & .permissionmodel {
    width:500px;
  }
  & .permissionlist {
    display: flex;
    flex-direction: column;
    max-height: 220px;
    overflow: auto;
  }
}
