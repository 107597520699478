/* .react-flow{
    background-color: red;
    width: '100%' !important;
    height: '60%' !important;
} */

.react-flow-parent {
  height: 530px;
  /* position: relative; */
}

@media (min-width: 1280px) {
  .react-flow-parent {
    height: 573px;
  }
}
@media (min-width: 1366px) {
  .react-flow-parent {
    height: 530px;
  }
}
@media (min-width: 1600px) {
  .react-flow-parent {
    height: 705px;
  }
}
@media (min-width: 1920px) {
  .react-flow-parent {
    height: 705px;
  }
}

.react-flow__attribution {
  visibility: hidden;
}

.floatingedges {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.floatingedges,
.react-flow__handle {
  opacity: 0;
}

.back-arrow {
  padding: 3px 3px;
  background: #fefefe;
  border: 1px solid #d8d6d8;
  width: 24px;
  margin: 10px 13px;
  position: relative;
  top: 8px;
  z-index: 5;
}
.back-arrow:hover {
  background: #efefef;
}

.loader {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
