.integration_button {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 16px;
  padding: 14px 20px 20px 32px;
}
.channel_content {
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-content: flex-start;

  & .channel_box {
    padding: 10px 0px;
    padding-left: 10px;
    width: 30%;
    height: 288px;
    margin: 16px;

    & .channel_sub_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      background-color: #fff;
      padding: 16px;
      box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
      border-radius: 6px;

      & .logo_content{
        display: flex;
        text-align: center;
        width: 100%;
        padding-bottom: 16px;
        border-bottom-width: 1px;
      }
      & .logo_content img{
        margin-right: 8px;
        height: 30px;
      }
      & .logo_content p{
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
      }
      & .res_screen {
        margin: 8px 0px;
        font-size: 13px;
        line-height: 16px;
      }
      & .margin_class {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .res_screen {
    display: -webkit-box;
    max-width: 400px;
    height: 60px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.625;
  }
  .channel_box {
    height: 220px;
  }
  .margin_class{
    margin-top: 15px;
  }
}