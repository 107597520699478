@import "~styles/colors";
@import "~styles/typography";

.title {
  margin: 30px 0px 15px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.soarcastSection {
  background-color: #f8f8fb;
  padding: 22px 20px;
  border-radius: 10px;
  margin-bottom: 22px;

  .tagsController {
    display: flex;
    column-gap: 16px;
    margin-top: 18px;

    .editMode {
      color: #8490a5;
      &::before {
        content: "\2715";
        font-size: 0.7rem;
        display: contents;
      }
    }

    .editIcon {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}

.soarcastTitle {
  margin-top: 18px;
  @include font($mainFont, 14px, 16px, 500);
}

.selectFieldsContainer {
  display: flex;
  column-gap: 10px;
  margin: 12px 0px 27px;

  .select {
    width: 249px;
  }
}

.parametersContainer {
  display: grid;
  width: fit-content;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-top: 18px;

  .variableInput {
    width: 249px;
  }
}

.buttonsGroup {
  display: flex;
  column-gap: 10px;
  margin-top: 27px;

  .buttonsGroupItem {
    padding: 12px 35px;
  }
}

.sectionTitle {
  @include font($mainFont, 14px, 16px, 500);
}

.addBtn {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: $secondaryBlue;
  border-radius: 5px;
  margin-left: 5px;
  align-self: center;
}

.optionsContainer {
  width: 100%;
  height: auto;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $dividerColor;
  border-radius: 8px;
  gap: 5px;
  padding: 7px;
  box-sizing: border-box;

  .tag {
    width: fit-content;
    height: 34px;
    background: $secondaryBackground;
    border-radius: 4px;
    @include font($mainFont, 12px, 14px);
    color: $darkText;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 18px;
    cursor: pointer;
    position: relative;
    align-self: center;

    &:hover {
      background: $mainText;
      color: $white;

      & .removeBtn {
        display: block;
        color: $primaryRed;
      }
    }

    & .removeBtn {
      display: none;
      position: absolute;
      top: -3px;
      right: -3px;
      transform: rotate(90deg);
      line-height: 5px;
    }
  }
}

// SChedule

.switchBoxHeader {
  display: flex;
  margin: 8px 0px;

  .switchBoxLabel {
    display: flex;
    align-items: center;
    @include font($mainFont, 14px, 16px, 500);
    color: $mainText;
  }
}

.weekDayContainer {
  width: fit-content;
  margin-top: 18px;
}

.inputsBlock {
  display: grid;
  width: fit-content;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 18px;

  .blockInput {
    width: 249px;
  }

  .dateInput {
    background-color: #fff;
  }
}

.summaryTitle {
  margin: 18px 0px 12px;
  @include font($mainFont, 16px, 19px, 500);
  color: $mainText;
}

.summary {
  max-width: 440px;
  margin-bottom: 18px;
  @include font($mainFont, 14px, 16px, 500);
  color: $greyText;
}

.tokenContainer {
  position: absolute;
  top: -15%;
  right: 0;
}
