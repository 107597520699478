@import "~styles/colors";
@import "~styles/typography";

.switchBoxBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.switchBoxHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.switchBoxLabel {
  @include font($boldFont, 18px, 87.59%);
  color: $mainText;

  margin-right: 5px;
}

.switchBoxContent {
  align-self: stretch;
  margin-top: 20px;
}

.hide{
  display: none;
}