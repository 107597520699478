@import "~styles/fonts/styles.css";

// $lightFont: PoppinsLight;
// $mainFont: Poppins;
// $mediumFont: PoppinsMedium;
// $boldFont: PoppinsBold;
// $heavyBoldFont: PoppinsHeavyBold;
// $lightItalicFont: PoppinsThinItalic;

$lightFont: InterLight;
$mainFont: Inter;
$mediumFont: InterMedium;
$boldFont: InterBold;
$heavyBoldFont: InterHeavyBold;
$lightItalicFont: InterThinItalic;


@mixin font($fontFamily, $fontSize, $lineHeight, $fontWeight: normal, $fontStyle: normal) {
  font-family: $fontFamily;
  font-style: $fontStyle;
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
}

.fa {
  font-family: "FontAwesome" !important;
}