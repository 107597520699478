@import "~styles/colors";
@import "~styles/typography";

.panel {
  background: #ffffff;
  box-shadow: 0px 0px 26px rgba(161, 165, 180, 0.08);
  border-radius: 15px;
  padding: 30px;
}

.panelHead {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  .button {
    width: 160px;
    height: 40px;
    border: none;
    background: #f0f5ff;
    border-radius: 10px;
    cursor: pointer;
    @include font($mediumFont, 14px, 16px);
    color: $primaryText;
    &:last-child {
      margin-left: 10px;
    }
  }
}

.editButton {
  margin-left: 10px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;
  margin-bottom: 20px;
}
