@import "~styles/colors";
@import "~styles/typography";

.modalFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.content {
  margin-top: 38px;
  .title {
    @include font($mainFont, 14px, 16px);
    color: $dark;
  }
  .cont {
    margin-left: 18px;
    .title {
      @include font($boldFont, 16px, 18px);
      color: $dark;
      margin-bottom: 8px;
    }
    .description {
      @include font($mediumFont, 12px, 14px);
      color: $greyText;
    }
  }
}

.form {
  margin-top: 30px;
  .inputContainer {
    margin-top: 18px;
  }
  .select {
    margin-top: 18px;
  }
  .row {
    position: relative;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid $dividerColor;
    padding-bottom: 10px;
    .select:first-child {
      margin-right: 20px;
    }
    .buttonDelete {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      top: 0;
      right: 0;
      top: 10px;
      cursor: pointer;
    }
  }
}
