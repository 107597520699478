@import "~styles/colors";
@import "~styles/typography";

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 15px;
  .switch {
    margin-left: 0;
    flex-direction: row;
  }
}

.fieldsContainer {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 20px;

  .column {
    display: grid;
    gap: 10px;

    &:nth-child(2) {
      grid-template-rows: repeat(4, minmax(0, 80px));
    }

    &:last-child {
      grid-template-rows: repeat(2, minmax(0, 170px));
      gap: 10px;

      & textarea {
        height: 135px;
      }
    }
  }
}

.controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 20px;

  .alert {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 13px;
    padding-left: 15px;
    border-radius: 6px;
    background-color: $secondaryRed;
    @include font($mainFont, 12px, 14px);
    color: $primaryRed;
  }

  .btn {
    width: 107px;
    height: 40px;
  }
}
