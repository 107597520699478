.container::-webkit-scrollbar {
  display: none;
}
.container::-webkit-scrollbar-thumb {
  display: none;
}

.title {
width: 246px;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;

color: #2B3858;
}