@import "~styles/colors";
@import "~styles/typography";

.expandBtnBody {
  margin-bottom: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
}

.expandBtnLabel {
  color: $primaryBlue;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-right: 10px;
}

.expandContainer {
  transition: max-height 0.5s;
  overflow: hidden;
}

.contentBody {
  padding: 30px;
  background: #f8f8fb;
  border-radius: 10px;
}
