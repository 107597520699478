@import "~styles/colors";
@import "~styles/typography";

.title {
  padding: 30px;
  margin-bottom: 20px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.li {
  text-align: start;
  height: 55px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $dividerColor;
}
