@import "~styles/colors";
@import "~styles/typography";

.headerBox {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerBtn {
  color: $primaryRed;
  @include font($mediumFont, 16px, 19px);
  text-decoration: underline;
  cursor: pointer;
}

.groupSelect {
  width: 100%;

  margin-top: 30px;
  margin-bottom: 28px;
}

.buttonBlock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.groupNameInput {
  margin: 30px 0;
}

.spreadButtonBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}