@import "~styles/colors";
@import "~styles/typography";

.keyValueEditBody {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.pairBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  margin-bottom: 20px;
}

.pairInput {
  width: 43%;
}

.addBtnBody {
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
}

.addBtnIcon {
  width: 24px;
  height: 24px;

  margin-right: 15px;

  background-color: $secondaryBlue;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  & path {
    fill: $primaryBlue;
  }
}

.addBtnLabel {
  @include font($mediumFont, 14px, 16px);
  color: $primaryBlue;
}
