@import "~styles/colors";
@import "~styles/typography";

.action {
  display: flex;
  flex-direction: column;
  background: $white;
  gap: 1rem;
  padding: 50px 0;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__filter {
      display: flex;
      width: 80%;
      gap: 10px;
      &-option {
        width: 30%;
      }
      &-type {
        width: 20%;
      }
    }
  }
}
.refreshbtn {
  margin-right: 8px;
  border: #cccccc solid 1px;
  color: #2b3858;
  border-radius: 10px;
}
