.container {
  width: 566px;
  height: 290px;
}
.title {
  width: 246px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2B3858;
  }