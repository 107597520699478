@import "~styles/colors";
@import "~styles/typography";

.refreshBox{
	display: flex;
	border-radius: 5px;
	border: 1px solid #cccccc;
	background: #fff;
	justify-content: center;
	align-items: center;
  width: 100%;

  & .refreshInput {
    height: 32px;
    border-right: 0.5px solid #cccccc;
  }
}
@media (min-width: 1700px) {
	.refreshBox {
		height: 42px;
	}
}