@import "~styles/colors";
@import "~styles/typography";


.labelAnticlockwise{
  transform-box: fill-box;
  transform-origin: center;
  transform: rotate(180deg);
}

.labelClockwise{
  transform-box: fill-box;
  transform-origin: center;
  transform: rotate(0deg);
  stroke: "";
}

.transparentPath{
  opacity: 0.2;
}
