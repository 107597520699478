@import "~styles/colors";
@import "~styles/typography";

.topContainer {
  display: flex;
  justify-content: space-between;
  margin: 25px 28px;

  .title {
    display: flex;
    align-items: center;
    @include font($mainFont, 16px, 18px, 600);
  }

  .closeIcon {
    cursor: pointer;
  }
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  margin: 18px 24px;

  .step {
    color: $primaryText;
    @include font($mainFont, 14px, 22px, 500);
  }
}
