@import "~styles/colors";
@import "~styles/typography";

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 27px;
    width: 550px;
    margin: 0 auto;
    padding-top: 27px;

    .showSteps {
      cursor: pointer;
      @include font($mainFont, 12px, 18px, 400);
      float: right;
      padding-bottom: 5px;
    }

    .btnContainer {
      display: flex;
      justify-content: end;
      column-gap: 21px;
      margin: 61px 0px;
    }
  }

  .stepsContainer {
    width: 420px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
}
