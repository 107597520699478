@import "~styles/colors";
@import "~styles/typography";

.container {
  height: 40px;
  position: relative;

  & .label {
    margin-bottom: 10px !important;
    @include font($mainFont, 14px, 16px);
    color: $mainText;

    & .required {
      color: $primaryRed;
    }
  }

  & .input {
    width: 0;
    height: 100%;
    position: absolute;
    right: 30px;
    top: 0;
    border-radius: 6px;
    border: none !important;
    box-shadow: none !important;
    background-color: $background;

    -webkit-transition: width 0.4s ease-in-out;
    -moz-transition: width 0.4s ease-in-out;
    -o-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;

    & input {
      height: 100%;

      padding: 0 12px;
      @include font($mainFont, 14px, 14px, 400);
      color: $mainText;
    }

    &::before {
      content: none !important;
    }

    &::after {
      content: none !important;
    }

    & input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      border: none !important;
      -webkit-box-shadow: 0 0 0px 1000px $background inset;
      border-radius: 6px;
    }
  }

  & .input.open {
    width: 200px;
    -webkit-transition: width 0.4s ease-in-out;
    -moz-transition: width 0.4s ease-in-out;
    -o-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
  }

  .error {
    border-color: $primaryRed;
    color: $primaryRed;
  }

  .error-text {
    margin-top: 10px;
    @include font($mainFont, 14px, 16px);
    color: $primaryRed;
  }
}
