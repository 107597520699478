@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 25px 25px 25px 30px;
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  margin-bottom: 0;
}

.content {
  margin-top: 20px;

  .row {
    gap: 25px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 15px;
    .label {
      @include font($mainFont, 12px, 14px);
      color: #7c818d;
    }
    .value {
      @include font($mainFont, 12px, 14px);
      color: $mainText;
    }
  }

  .blockRow {
    display: flex;
    justify-content: space-between;

    .block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &::after {
        content: "";
        width: 76px;
        height: 5px;
        border-radius: 4px;
      }
      &.red {
        &::after {
          background: #d83e3c;
        }
      }
      &.green {
        &::after {
          background: #9eec60;
        }
      }
      &.orange {
        &::after {
          background: #febd46;
        }
      }
      &.darkBlue {
        &::after {
          background: #576aca;
        }
      }
      &.blue {
        &::after {
          background: #379eff;
        }
      }

      .count {
        @include font($mainFont, 32px, 38px, 600);
        color: $mainText;
      }
      .title {
        position: relative;
        display: flex;
        flex-direction: column;
        @include font($mainFont, 14px, 16px);
        color: $mainText;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
}
