@import "~styles/colors";
@import "~styles/typography";

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 20px;
  padding-top: 77px;
  background: $background;
  border-radius: 10px;
  cursor: pointer;
  min-height: 177px;
}

.delete {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.name {
  @include font($boldFont, 14px, 16px);
  color: $mainText;
}

.title {
  @include font($boldFont, 18px, 20px);
  color: $mainText;
  margin-bottom: 5px;
}

.description {
  @include font($mediumFont, 14px, 16px);
  color: $greyText;
  margin-top: 8px;
  margin-bottom: 13px;
}
