@import "~styles/colors";
@import "~styles/typography";

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  // margin-left: 171px;
  flex-direction: column;
  gap:10px
}

.bg {
  position: relative;
  display: flex;
  flex: 1;
  height: 100vh;
}

.logo {
  position: absolute;
  top: 40%;
  right: 30%;
}

.input {
  height: 64px !important;
  width: 426px !important;
  border-radius: 10px ;
  box-shadow: 0px 4px 10px 3px #0000001C;
}

.input:focus{
    border: 1px solid #1565D8;
}

.inputContainer {
    height: 64px !important;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
}

.button {
  margin-top: 32px;
  background-color: #3200C1;
  width: 11rem;
  height: 3rem;
  font-size: 1.125rem;
  border-radius: 0.375rem;
  margin-top: -15px;
}

.button:hover{
  background-color: #1F087B;
}


.authCard {
  width: 32rem;
  height: 39rem;
  background-color: #1F087B;
  border-top-left-radius: 0.9375rem;
  border-bottom-left-radius: 0.9375rem;
  padding-inline-start: 4rem;
  padding-inline-end: 4rem;
  padding-bottom: 1rem;
  padding-top: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  flex-direction: column
}

.formSignIn{
  width:44.063;
  height: 39rem;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 45px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background-color: white;
  border-top-right-radius: 0.9375rem;
  border-bottom-right-radius: 0.9375rem;
  border: 1px solid rgba(0,0,0,0.1);
  padding-top: 3rem;
  padding-bottom: 3.5rem;
  padding-inline-start: 6rem;
  padding-inline-end: 4rem;
}

