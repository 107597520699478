@import "~styles/colors";
@import "~styles/typography";

.searchContainer {
  width: 100%;
  max-width: 342px;
}

.search {
  background: #f3f5f9;
  padding: 9px 12px !important;
  border: none !important;
}
