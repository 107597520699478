@import "~styles/colors";
@import "~styles/typography";

.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  & > button {
    align-self: flex-start;
  }
}

.searchHeader {
  padding: 25px 25px 25px 30px;
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  margin-bottom: 0;
}

.tableRow {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid $dividerColor;
  padding: 10px 0;
}

.tableHead {
  width: calc(100% - 12px); // padding + scrollbar from list
  padding: 10px 20px 0px 30px;
  border-bottom: none;

  & span {
    @include font($mediumFont, 16px, 19px);
  }
}

.scrollableList {
  padding: 13px 20px 30px 30px;
  margin: 0;
  @include font($mainFont, 16px, 16.25px);
  color: $mainText;

  & .li {
    text-align: start;
    height: 55px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $dividerColor;
  }

  & .noData {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: #83b0fd;
    }
  }
}
