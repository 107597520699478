@import "~styles/colors";
@import "~styles/typography";

.form {
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  .fieldContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 35px;
  }
}

.fieldLabel {
  margin-bottom: 10px;
  @include font($mainFont, 14px, 16px, 400);
}

.roleARN {
  display: flex;
  column-gap: 9px;
  align-items: center;

  span {
    color: $greyText;
    @include font($mainFont, 16px, 24px, 400);
  }
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  column-gap: 21px;
  margin: 26px 0px 61px;
}

.regions {
  width: 50%;

  .regionsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .regionButtons {
    display: flex;
    justify-content: space-between;

    .select {
      cursor: pointer;
      @include font($mainFont, 12px, 18px, 400);
    }
  }
}
