@import "~styles/colors";
@import "~styles/typography";

.container {
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 18px;

  & .datePicker {
    min-width: 180px;
  }

  & .table {
    margin-top: 30px;
  }
}

.tableContainer {
  margin-top: 20px;
  padding: 30px 30px 18px;
}

.searchRow {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 20px;

  & .searchContainer {
    width: 342px;
  }

  & .select {
    max-width: 165px;
  }

  & .btn {
    padding: 0 25px;
  }

  & .search {
    background: #f3f5f9;
    padding: 9px 12px !important;
    border: none !important;
  }

  .filters {
    gap: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.loader {
  position: absolute;
  left: 50%;
}
