@import "~styles/colors";
@import "~styles/typography";

.content {
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  border-radius: 15px 15px 0px 0px;
}

.filters {
  padding: 30px;
  background: #ffff;
  border-radius: 15px 15px 0px 0px;
}

.searchContainer {
  width: 342px;
}

.search {
  background: #f3f5f9;
  padding: 9px 12px !important;
  border: none !important;
}

.filterRow {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.selectsGroup {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  .select {
    min-width: 200px;
    // margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.buttonGroup {
  display: flex;
  .actionButton {
    margin-left: 16px;
  }
}

.table {
  position: relative;
}

.subTable {
  background-color: $secondaryBackground;
}
