@import "~styles/colors";
@import "~styles/typography";

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  & .label {
    @include font($mainFont, 14px, 16px);
    color: $mainText;
    margin-bottom: 10px;
  }

  & .textarea {
    height: 85px;
    padding: 10px;
    resize: none;
    border: 1px solid $dividerColor;
    border-radius: 8px;
    background-color: $white;
    @include font($mainFont, 14px, 21px);
    color: $mainText;
    box-sizing: border-box;

    &:disabled{
      background-color: #F2F5FA;
    }
    
    &:focus {
      border-color: $dividerColor;
      box-shadow: none;
      outline: none;
    }
  }
}

.container.required {
  & .label {
    &::after {
      content: "*";
      color: $primaryRed;
      font-weight: 600;
      margin-left: 2px;
    }
  }
  // & .label {
  //   color: $primaryRed;
  // }

  // & .textarea {
  //   border: 1px solid $primaryRed;
  // }
}
