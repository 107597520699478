@import "~styles/colors";
@import "~styles/typography";

.title {
  margin-bottom: 30px;
}

.input {
  margin-bottom: 20px;
}

.queryContainer {
  background: #fafafa;
  border: 1px solid #dbdff0;
  box-sizing: border-box;
  border-radius: 4px;
  height: auto;
}
.headingNumber {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
}
.query {
  padding: 0 0 0 10px;
  font-size: 12px;
}
.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  & .btn {
    height: 40px;
    padding: 0 35px;
  }
}
