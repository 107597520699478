@import "~styles/colors";
@import "~styles/typography";

.title {
  margin-bottom: 30px;
}

.btnsRow {
  display: flex;
  justify-content: space-between;

  .btn {
    height: 40px;
    padding: 0 35px;
  }
}

.textarea {
  margin-bottom: 30px;

  & textarea {
    height: 135px !important;
  }
}
