@import "~styles/colors";
@import "~styles/typography";

.title {
  margin: 0 0 30px;
}

.vulnTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;

  & > div {
    @include font($mediumFont, 20px, 30px, 500);
    color: $mainText;
  }

  & > button {
    margin-left: 5px;
  }
}

.card {
  background: $secondaryBackground;
  border-radius: 10px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  & .part {
    padding: 20px;
    display: flex;
    &.borderNone {
      border-bottom: none !important;
    }
    &:first-child {
      border-bottom: 1px solid $dividerColor;
      justify-content: space-between;
      align-items: flex-start;

      & .name {
        @include font($mediumFont, 20px, 30px);
        color: $mainText;
      }

      & .controls {
        display: flex;
        align-items: center;

        & .noComments {
          pointer-events: none;
        }

        & > div {
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            & > span {
              transform: scale(1.4);
            }
          }

          & > svg {
            margin: 0 5px;
          }

          & > span {
            @include font($mediumFont, 14px, 21px);
            color: $primaryRed;
            margin-right: 13px;

            -webkit-transition: transform 0.15s ease-in-out;
            -moz-transition: transform 0.15s ease-in-out;
            -o-transition: transform 0.15s ease-in-out;
            transition: transform 0.15s ease-in-out;
          }
        }
      }
    }

    &:last-child {
      flex-direction: column;

      & > span:first-child {
        @include font($mediumFont, 12px, 14px, 500);
        color: $greyText;
        margin-bottom: 5px;
      }

      & > span:last-child {
        @include font($mediumFont, 14px, 21px, 400);
        color: $mainText;
      }
    }
  }
}

.addBtn {
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  padding: 0;

  & > svg {
    transform: scale(0.8);
  }
}

.btnsRow {
  display: flex;
  justify-content: space-between;

  .btn {
    height: 40px;
    padding: 0 35px;
  }
}

.input {
  margin-bottom: 30px;
}
