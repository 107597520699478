@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;

  & .inputsCont {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin-top: 30px;
  }

  & .checkbox {
    display: block;
    margin-top: 20px;
    margin-left: 0;
  }

  & .btnsCont {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;

    & .btn {
      width: 105px;
      height: 40px;
      padding: 0 35px;
    }
  }
}
