@import "~styles/colors";
@import "~styles/typography";

.form {
  margin-top: 2.25rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .input {
    height: 3rem !important;
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
