@import "~styles/colors";
@import "~styles/typography";

.container {
  padding: 30px;
}

.action {
  display: flex;
  flex-direction: column;
  background: $white;
  gap: 1rem;
  padding: 15px 0;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__filter {
      display: flex;
      width: 80%;
      gap: 10px;
      &-option {
        width: 30%;
      }
      &-type {
        width: 30%;
      }
    }
  }
}

.actionbtn {
  &-group {
    display: flex;
    margin-left: auto;
    gap: 10px;
    justify-content: space-between;
  }
}
