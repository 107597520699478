.react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #ccc;
  }
  .react-datepicker__navigation {
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    overflow: inherit;
  }
  
  .react-datepicker button:focus {
    outline: none;
  }
  .range-text{
    font-weight: 600;
    font-size: 14px;
    color: #05147D;
  }
