@import "~styles/colors";
@import "~styles/typography";

.container {
  max-height: 85vh;
  overflow: auto;
  overflow-y: auto;
  margin-top: 20px;
  padding-right: 20px;
}

.wrapper {
  margin-top: 20px;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
