.placeholderText {
  color: #b8b8b8;
  font-size: 18px;
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  left: 35%;
}

.code-editor-wrapper {
  position: relative;
}

.CodeMirror * {
  font-family: monospace !important ;
  font-size: 14px;
}
