@import "~styles/colors";
@import "~styles/typography";

.panel {
  background: #ffffff;
  box-shadow: 0px 0px 26px rgba(161, 165, 180, 0.08);
  border-radius: 15px;
  padding: 30px;
  margin-top: 15px;
}

.buttonGroup {
  display: flex;
}

.editButton {
  margin-right: 10px;
  &.right{
    margin-left: auto;
  }
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;
  margin-bottom: 20px;
}

.row2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  margin-bottom: 20px;
}
