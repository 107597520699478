@import "~styles/colors";
@import "~styles/typography";

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;

  & > a {
    text-decoration: none !important;
  }
}

.labelFilter {
  width: 50%;
  margin-top: 10px;
}

.buttonGroup {
  display: flex;
  .select {
    width: 162px;
    min-width: 162px;
    margin-right: 10px;
  }
}

.button {
  height: 40px;
  padding: 0 24px;
  flex-shrink: 0;
}

.contentGrid {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;

  & .gridColumn {
    & > div {
      margin-bottom: 20px;
    }
  }
}

.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 25px 25px 30px;
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  margin-bottom: 0;

  & > button {
    align-self: flex-start;
  }
}

.titleSection {
  @include font($mediumFont, 18px, 21px);
  color: $primaryText;
  margin: 0 0 15px 0;
}

// .title_wrapper {
//   width: 90%;
// }

// .title {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

.text {
  @include font($mainFont, 14px, 21px);
  color: $primaryText;
  margin: 0 0 30px 0;
  white-space: pre-wrap;
  // &.scroll {
  //   max-height: 300px;
  //   overflow: auto;
  // }
}

.scrollableList {
  padding: 13px 20px 30px 30px;

  & ul {
    height: 165px;
    overflow-y: auto;
    padding: 0;
    padding-right: 6px;
    margin: 0;
    @include font($mainFont, 16px, 16.25px);
    color: $mainText;

    & li {
      text-align: start;
      height: 55px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $dividerColor;
      cursor: pointer;
    }
  }

  & a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: #83b0fd;
    }
  }

  & .assetHead {
    width: calc(100% - 12px); // padding + scrollbar from list
    padding: 10px 0;

    & span {
      @include font($mediumFont, 16px, 19px);
    }
  }

  & .assetRow {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    cursor: initial;

    & .select {
      width: 90%;
    }
  }

  & .noData {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// comments
.commentsHeader {
  padding: 0;
  box-shadow: none;
  margin-bottom: 25px;
}

.commentsContent {
  padding: 25px 25px 30px 30px;

  .commentsSection {
    display: flex;
    margin-bottom: 12px;

    p {
      margin: 0;
    }

    & .quantity {
      margin: 0 5px 0 10px;
      @include font($mediumFont, 14px, 21px);
      color: $primaryRed;
    }
  }

  .textField {
    margin-bottom: 20px;
  }

  .showCommentsBtn {
    background-color: #f0f5ff;
    color: $mainText;
    -webkit-transition: background-color 0.15s ease-in-out;
    -moz-transition: background-color 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: #dfebff;
    }
  }
}

.editMode {
  min-height: 505px;
  display: flex;
  flex-direction: column;

  & .buttonsContainer {
    margin-top: auto;

    & .button {
      padding: 0 35px;
    }
  }
}

// history
.historyList {
  & .headElem {
    width: calc(100% - 12px); // padding + scrollbar from list
    padding: 10px 0;

    & span {
      @include font($mediumFont, 16px, 19px);
    }
  }

  & .listElem {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & .status {
      width: fit-content;
      line-height: 23px;
      padding: 3px 10px;
      background: #88acd6;
      border-radius: 3px;
      text-transform: capitalize;
      color: $white;
    }

    & .status.disconnected {
      background: $primaryRed;
    }
  }
}

// statistics
.statisticsContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  position: relative;
  padding: 40px;

  & .block {
    display: flex;
    align-items: center;
    position: relative;

    & .control {
      position: absolute;
      top: -15px;
      right: -15px;
    }

    & .line {
      height: 80px;
      width: 6px;
      background: #9eec60;
      border-radius: 4px;
      margin-right: 14px;
    }

    & .name {
      color: $mainText;

      & > div:first-child {
        @include font($mediumFont, 24px, 29px);
        color: inherit;
        margin-bottom: 10px;
      }

      & > div:last-child {
        @include font($mediumFont, 14px, 17px);
        color: inherit;
        text-transform: capitalize;
      }
    }
  }
}
