// @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&display=swap');
@import "colors";
@import "typography";

* {
  // font-family: "Poppins", "Times New Roman", serif;
  font-family: "Inter", "Times New Roman", serif;
  box-sizing: border-box;
}

/*scrollbars customeizing*/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eaeef6;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #97a2b8;
}
::-webkit-scrollbar-corner {
  display: none;
}

:root {
  scrollbar-color: #97a2b8 #eaeef6;
  scrollbar-width: 5px;
}
