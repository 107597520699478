@import "~styles/colors";
@import "~styles/typography";

.container {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.checkbox {
  margin-top: 20px;
  margin-left: 0px;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 30px;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  & .button {
    height: 40px;
    padding: 0 35px;
  }
}
