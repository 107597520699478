/* @font-face {
  font-family: "PoppinsLight";
  src: url("./Poppins-Light.ttf") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "PoppinsThinItalic";
  src: url("./Poppins-ExtraLightItalic.ttf") format("woff2");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Regular.ttf") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PoppinsMedium";
  src: url("./Poppins-Medium.ttf") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "PoppinsBold";
  src: url("./Poppins-SemiBold.ttf") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "PoppinsHeavyBold";
  src: url("./Poppins-Bold.ttf") format("woff2");
  font-weight: 700;
  font-style: normal;
} */

@font-face {
  font-family: "InterLight";
  src: url("./Inter-Light.ttf") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "InterThinItalic";
  src: url("./Inter-ExtraLight.ttf") format("woff2");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Inter";
  src: url("./Inter-Regular.ttf") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "InterMedium";
  src: url("./Inter-Medium.ttf") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "InterBold";
  src: url("./Inter-SemiBold.ttf") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "InterHeavyBold";
  src: url("./Inter-Bold.ttf") format("woff2");
  font-weight: 700;
  font-style: normal;
}