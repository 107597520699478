.underline{
    border-bottom: 2px;
}
.label{
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    margin-top: 10px;
    color: #000000;
}
.radio{
    position: relative;
    right:14px;
  & .radiotext{
    position: relative;
    top:10px;
    & .radiolabel{
        font-weight: 600;
        font-size: 14px;
        color: #000000;
      }
      & .radioDescription{
        margin-left: 0;
        // color: #0c0808;
        font-size: 12px;
      }
  }
  
}
.button{
  margin-top: 5px;
  display: flex;
  justify-content: end;
}