@import "~styles/colors";
@import "~styles/typography";

.content {
  margin-top: 38px;
  .title {
    @include font($mainFont, 14px, 16px);
    color: $dark;
  }
}
