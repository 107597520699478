@import "~styles/colors";
@import "~styles/typography";

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .stepsContainer {
    width: 420px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }

  .form {
    width: 550px;
    margin: 0 auto;
    padding-top: 46px;

    .inputContainer {
      margin-bottom: 44px;

      .labelWrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        .label {
          @include font($mainFont, 16px, 18px, 600);
        }

        .showSteps {
          cursor: pointer;
          @include font($mainFont, 12px, 18px, 400);
        }
      }
    }
    .btnContainer {
      display: flex;
      justify-content: flex-end;
      column-gap: 21px;
      margin: 61px 0px;
    }
  }
}

.step {
  color: $primaryText;
  @include font($mainFont, 14px, 22px, 500);
}

.readOnly {
  padding: 0px 5px;
  border-radius: 4px;
  color: $greyText;
  background-color: rgba(155, 155, 155, 0.2);
  @include font($mainFont, 14px, 16px, 500);
}

.command {
  margin-bottom: 20px;
  color: $greyText;
  @include font($mainFont, 14px, 16px, 400);
}

.stepTitle {
  @include font($mainFont, 16px, 18px, 600);
}

.select {
  cursor: pointer;
  @include font($mainFont, 12px, 18px, 400);
}

.regionsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
