@import "~styles/colors";
@import "~styles/typography";
$screen1: 768;
$screen2: 900px;
$screen3: 1080px;
$screen4: 1200px;
$screen5: 1400px;
$screen6: 2160px;
.filter {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  padding: 25px 25px 25px 5px;
  box-shadow: 0px 9px 32px rgba(140, 145, 162, 0.08);
  margin-bottom: 20px;
}

.datePicker {
  min-width: 250px;
  background: #fff;
}

.sidebar {
  display: flex;
  background: #fff;
}

.dashboard {
  display: flex;
  height: 90vh;
  width: 100%;
}
.alertcount {
  background: #326ce5;
  color: white;
}
.clusterdetails {
  margin: 1.5rem 0rem 0rem 1.5rem;
}

.toggleBtn {
  @media screen and (max-width: 1300px) {
    width: 28%;
  }
}
